<script>
// Все (по возможности), что нужно для работы со статусами
import {
  getStatusColor,
  getStatusIcon,
  getStatusTitle,
  getStatusAction,
  statusesInWork,
  statusesRejected
} from '@/helpers/statuses';

export default {
  data: () => ({
    statusesInWork: statusesInWork,
    statusesRejected: statusesRejected
  }),
  methods: {
    getStatusColor: getStatusColor,
    getStatusIcon: getStatusIcon,
    getStatusTitle: getStatusTitle,
    getStatusAction: getStatusAction
  }
};
</script>
