<template>
  <v-main>
    <Sidebar />
    <ObjectsTable
      v-if="Object.keys(classData).length"
      :classData="classData"
      :isUserRelevant="isUserRelevant"
    />
    <CockpitPlaceholder v-else />
    <!-- Диалог с карточкой класса может быть открыт
    из разных компонентов, поэтому поместим ее сюда.
    Диалог вынесен за пределы компонента, потому что карточка
    может потребоваться за пределами диалога
    -->
    <v-dialog
      v-model="$store.state.class.showClassCard"
      max-width="60%"
      @keydown.esc="$store.commit('CLASS_MODAL', false)"
      scrollable
      persistent
    >
      <ClassCard
        v-if="$store.state.class.showClassCard"
        :classData="$store.state.class.data"
        @close="$store.commit('CLASS_MODAL', false)"
      />
    </v-dialog>
  </v-main>
</template>

<script>
// Собранный компонент основного приложения по согласованию
import Sidebar from '@/components/layout/Sidebar';
import ObjectsTable from '@/components/main/ObjectsTable';
import ClassCard from '@/components/main/ClassCard';
import CockpitPlaceholder from '@/components/main/CockpitPlaceholder';

export default {
  name: 'Main',
  components: { CockpitPlaceholder, Sidebar, ObjectsTable, ClassCard },
  data: () => ({
    showClassCard: false
  }),
  computed: {
    classData() {
      return this.$store.state.class.selectedClass;
    },
    isUserRelevant() {
      return this.$store.state.class.data.user_relevant;
    }
  },
  mounted() {
    // Получим некоторые начальные данные
    this.$store.dispatch('fetchConfig', 'main');
    this.$store.dispatch('fetchUserStatusCounts');
  }
};
</script>
