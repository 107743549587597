import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';

import api from '@/api';

import { downloadBinaryFile } from '@/helpers/file';

Vue.use(Vuex);

// Стейт возвращаем из функции, чтобы иметь удобную возможность его обнулять
const initState = () => ({
  isLoading: false,
  isAuthenticated: localStorage.getItem('isAuthenticated') || 'false',
  config: {
    user: {},
    tree: {
      treeVariants: [],
      selectedVariant: ''
    }
  },
  statusCounts: [],
  tree: {
    treeData: [],
    classesToUpdate: []
  },
  table: {
    tableData: []
  },
  object: {
    data: {}
  },
  class: {
    selectedClass: {},
    showClassCard: false,
    data: {}
  }
});

export default new Vuex.Store({
  state: initState(),
  mutations: {
    RESET(state) {
      Object.assign(state, initState());
    },
    CLASS_MODAL(state, isShown) {
      state.class.showClassCard = isShown;
    },
    SET_IS_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_LOADING_STATUS(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_SELECTED_VARIANT(state, variant) {
      state.config.tree.selectedVariant = variant;
    },
    SET_CONFIG(state, data) {
      state.config.user = data.user;

      if (data.tree) {
        state.config.tree.treeVariants = data.tree.tree_variants;
        state.config.tree.selectedVariant = data.tree.selected_variant;
      }
    },
    SET_STATUS_COUNTS(state, statistics) {
      state.statusCounts = statistics;
    },
    SET_TREE_DATA(state, treeData) {
      state.tree.treeData = treeData;
    },
    SET_TABLE_DATA(state, tableData) {
      state.table.tableData = tableData;
    },
    SET_OBJECT_DATA(state, objectData) {
      state.object.data = objectData;
    },
    SET_SELECTED_CLASS(state, selectedClass) {
      state.class.selectedClass = selectedClass;
    },
    SET_CLASS_DATA(state, classData) {
      state.class.data = classData;
    },
    ENRICH_SELECTED_CLASS(state, comment) {
      state.class.selectedClass.commentCategory = comment.commentCategory;
      state.class.selectedClass.commentText = comment.commentText;
    },
    SET_CLASS_META_FOR_UPDATE(state, classesMeta) {
      state.tree.classesToUpdate = classesMeta;
    }
  },
  getters: {
    isInconUser(state) {
      return state.config.user.incon_id > 0;
    },
    isSuperOrInconUser(state) {
      return state.config.user.is_superuser || state.config.user.incon_id > 0;
    }
  },
  actions: {
    async login({ commit, dispatch }, { username, password }) {
      let response = await api
        .post('/api/auth/login', { username, password })
        .then(response => {
          localStorage.setItem('isAuthenticated', 'true');
          commit('SET_IS_AUTHENTICATED', 'true');

          router.push({ name: 'main' });
          return response;
        });
      return response;
    },
    async logout({ commit, dispatch }) {
      let response = await api.post('/api/auth/logout').then(response => {
        localStorage.setItem('isAuthenticated', 'false');
        commit('SET_IS_AUTHENTICATED', 'false');
        commit('RESET'); // Важно!
        router.push({ name: 'logout' });
        return response;
      });
      return response;
    },
    async changePassword(
      { commit },
      { username, password, newPassword, newPasswordRepeated }
    ) {
      let response = await api.post('/api/auth/change', {
        username,
        password,
        newPassword,
        newPasswordRepeated
      });
      return response;
    },
    async sendRecoveryEmail({ commit }, { email }) {
      let response = await api.post('/api/auth/recover', { email });
      return response;
    },
    async changePasswordWithToken(
      { commit },
      { uid, token, newPassword, newPasswordRepeated }
    ) {
      let response = await api.put('/api/auth/recover', {
        uid,
        token,
        newPassword,
        newPasswordRepeated
      });
      return response;
    },
    async fetchConfig({ commit }, view) {
      // получение требуемых статичных данных для приложения
      let response = await api.get(`/api/config/${view}`);
      commit('SET_CONFIG', response.data);
    },
    async fetchUserStatusCounts({ commit }) {
      // Статистика статусов пользователя
      let response = await api.get('/api/user/statistics');
      commit('SET_STATUS_COUNTS', response.data);
    },
    async fetchTreeModel({ dispatch, commit }, params) {
      let response = await api.get('/api/classes', { params: params });
      commit('SET_TREE_DATA', response.data.tree);
    },
    async fetchObjects({ commit, state }) {
      let cls_id = state.class.selectedClass.cls_id;
      let response = await api.get(`/api/classes/${cls_id}/objects`);
      commit('SET_TABLE_DATA', response.data.class_content);

      // Добавляем в данные выбранного класса комментарии по нему
      const comment = {
        commentCategory: response.data.last_class_comment_category,
        commentText: response.data.last_class_comment_text
      };

      commit('ENRICH_SELECTED_CLASS', comment);
    },
    async getObjectInfo({ commit }, obj_id) {
      let response = await api.get(`/api/objects/${obj_id}`);
      commit('SET_OBJECT_DATA', response.data);
    },
    async getClassInfo({ commit }, cls_id) {
      let response = await api.get(`/api/classes/${cls_id}`);
      commit('SET_CLASS_DATA', response.data);
    },
    async updateObjects({ commit }, data) {
      let response = await api({
        method: 'post',
        url: '/api/objects',
        data: data
      });
      return response;
    },
    async updateClass({ commit }, { cls_id, data }) {
      let response = await api({
        method: 'post',
        url: `/api/classes/${cls_id}`,
        data: data
      });
      return response;
    },
    async excelDownload({ commit }, payload) {
      let response = await api({
        method: 'put',
        url: '/api/excel',
        data: payload,
        responseType: 'blob'
      });
      downloadBinaryFile(response);
    },
    async excelUpload({ commit }, formData) {
      let response = await api({
        method: 'post',
        url: '/api/excel',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response;
    },
    async getClassMeta({ commit }, cls_id) {
      let response = await api.get(`/api/classes/${cls_id}/meta`);
      commit('SET_CLASS_META_FOR_UPDATE', response.data);
    },
    async reportDownload({ commit }, payload) {
      let response = await api({
        method: 'put',
        url: '/api/report',
        data: payload,
        responseType: 'blob'
      });
      downloadBinaryFile(response);
    }
  },
  modules: {}
});
