// Фильтр для интерполяции Vue, рендерит число в округленное число с заданной точностью
export default function roundFilter(value, decimals) {
  if (!value) {
    value = 0;
  }

  if (!decimals) {
    decimals = 2;
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
}
