// Все, что связано со статусами хранится в одном месте, и импортируется отсюда,
// либо (для Vue компонентов), объединяется в миксин
export const statusesInWork = [0, 10];
export const statusesRejected = [20, 25];

export const STATUS_COLORS = {
  0: 'secondary',
  10: 'info',
  20: 'warning',
  25: 'error',
  99: 'success'
};

export const STATUS_ICONS = {
  0: 'mdi-checkbox-blank-circle',
  10: 'mdi-arrow-left-bold-circle',
  20: 'mdi-arrow-right-bold-circle',
  25: 'mdi-arrow-right-bold-circle',
  //  25: 'mdi-minus-circle-outline',  // слишком агрессивная картинка
  99: 'mdi-checkbox-marked-circle'
};

export const STATUS_TITLES = {
  0: 'На согласовании',
  10: 'Повторное согласование',
  20: 'На доработке',
  25: 'Класс на доработке',
  99: 'Согласовано'
};

export const STATUS_ACTIONS = {
  20: 'На доработку',
  25: 'Отклонить',
  99: 'Согласовать'
};

export const getStatusColor = function(status) {
  return STATUS_COLORS[status];
};

export const getStatusIcon = function(status) {
  return STATUS_ICONS[status];
};

export const getStatusTitle = function(status) {
  return STATUS_TITLES[status];
};

export const getStatusAction = function(status) {
  return STATUS_ACTIONS[status];
};

export const checkStatuses = function(rows) {
  // Функция для подсчета валидных статусов среди выбранных строк в таблице
  let statuses = {};

  rows.forEach(function(row) {
    let status = row.getData().stat_id;
    if (statuses.hasOwnProperty(status)) {
      statuses[status] += 1;
    } else {
      statuses[status] = 1;
    }
  });
  return (statuses[0] || 0) + (statuses[10] || 0); // количесиво валидных для отправки записей
};
