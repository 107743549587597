<template>
  <v-card>
    <v-card-title>
      Материал {{ objectData.main.hist_id || '[Без номера]' }} -
      {{ objectData.main.hist_short_name }}
      <v-spacer></v-spacer>
      <v-icon @click="$emit('close')">mdi-close</v-icon>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab>
          <v-icon left>mdi-view-list</v-icon>
          Атрибутика
        </v-tab>
        <v-tab>
          <v-icon left>mdi-archive</v-icon>
          История согласования
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card class="ma-1">
            <v-card-title>
              <v-icon left>mdi-file-document</v-icon>
              Наименования материала
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="3" class="py-1 subtitle-1 font-weight-medium">
                  Исходное
                </v-col>
                <v-col class="py-1">
                  {{ objectData.main.hist_short_name }}
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3" class="py-1 subtitle-1 font-weight-medium">
                  Исходное полное
                </v-col>
                <v-col class="py-1">{{ objectData.main.hist_full_name }}</v-col>
              </v-row>
              <v-row>
                <v-col md="3" class="py-1 subtitle-1 font-weight-medium">
                  Нормализованное
                </v-col>
                <v-col class="py-1">
                  {{ objectData.main.norm_short_name }}
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3" class="py-1 subtitle-1 font-weight-medium">
                  Нормализованное полное
                </v-col>
                <v-col class="py-1">{{ objectData.main.norm_full_name }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="ma-1">
            <v-card-title>
              <v-icon left>mdi-format-list-bulleted</v-icon>
              Значения атрибутов
            </v-card-title>
            <template v-if="objectData.values_table.length">
              <v-card-text>
                <v-simple-table dense>
                  <template #default>
                    <tbody>
                      <tr
                        v-for="item in objectData.values_table"
                        :key="item.dvs_id"
                      >
                        <td width="40%">
                          {{ item.dvs_name }}
                          <v-icon
                            v-if="item.mandatory"
                            x-small
                            title="Обязательный атрибут"
                          >
                            mdi-star
                          </v-icon>
                        </td>

                        <td v-if="item.valchar">
                          {{ item.valchar }}
                          <v-icon
                            v-if="item.status == 2"
                            small
                            title="Вероятное значение"
                          >
                            mdi-alert-circle-outline
                          </v-icon>
                        </td>
                        <template v-else>
                          <template v-if="isProcessable">
                            <td v-if="$store.getters.isInconUser">
                              Требуется уточнение
                            </td>
                            <!-- Если пользователь от заказчика, дадим дадим возможность
                            доопределить по клику-->
                            <td v-else>
                              <a @click="rejectObject(toClarifyComment)">
                                Требуется уточнение
                              </a>
                            </td>
                          </template>
                          <td v-else></td>
                        </template>
                        <td>{{ item.symsgn }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </template>
            <v-card-subtitle v-else>
              Присвоенные значения отсутствуют
            </v-card-subtitle>
          </v-card>
          <v-card class="ma-1">
            <v-card-title>
              <v-icon left>mdi-scale-balance</v-icon>
              Единица измерения
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="3" class="py-1 subtitle-1 font-weight-medium">
                  Исходная
                </v-col>
                <v-col class="py-1">{{ objectData.main.hist_uom }}</v-col>
              </v-row>
              <v-row>
                <v-col md="3" class="py-1 subtitle-1 font-weight-medium">
                  Нормализованная
                </v-col>
                <v-col class="py-1">{{ objectData.main.norm_uom }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="ma-1">
            <v-card-title>
              <v-icon left>mdi-information</v-icon>
              Дополнительная информация
            </v-card-title>
            <v-card-text v-if="objectData.add_attribute_values.length">
              <v-row
                v-for="item in objectData.add_attribute_values"
                :key="item.obj_attr__obj_attr_name"
              >
                <v-col md="3" class="py-1 subtitle-1 font-weight-medium">
                  {{ item.obj_attr__obj_attr_name }}
                </v-col>
                <v-col class="py-1">{{ item.obj_attr_val }}</v-col>
              </v-row>
            </v-card-text>
            <v-card-text v-else>
              Отсутствует дополнительная информация для материала
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-timeline align-top dense>
            <v-timeline-item
              v-for="comment in objectData.object_comments"
              :key="comment.com_obj_id"
              :color="getStatusColor(comment.stat_id)"
              :icon="getStatusIcon(comment.stat_id)"
              fill-dot
            >
              <v-card class="mx-1">
                <v-card-title>
                  <v-row>
                    <p class="ml-2">
                      <v-icon>mdi-message-text-outline</v-icon>
                      {{ comment.user_name }}
                    </p>
                    <v-spacer></v-spacer>
                    <p class="overline mr-2">
                      {{ comment.created_at | datetime }}
                    </p>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <p class="body-1">{{ comment.com_text }}</p>
                  <v-divider></v-divider>
                  <p class="font-weight-bold my-2">
                    {{ comment.old_status_name }}
                    <v-icon small>mdi-arrow-right</v-icon>
                    {{ comment.new_status_name }}
                  </p>

                  <div v-if="comment.attachment_url">
                    <v-btn
                      text
                      color="primary"
                      :href="comment.attachment_url"
                      download
                      title="Вложение"
                    >
                      <v-icon left>mdi-attachment</v-icon>
                      {{ getAttachmentName(comment.attachment_url) }}
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-timeline-item>
            <!-- Фиктивная точка с датой создания объекта -->
            <v-timeline-item
              :color="getStatusColor(0)"
              :icon="getStatusIcon(0)"
              fill-dot
            >
              <v-card>
                <v-card-text>
                  <v-row class="pa-2" align="center">
                    <v-icon left>mdi-state-machine</v-icon>
                    Материал направлен на согласование
                    <v-spacer></v-spacer>
                    <span class="overline">
                      {{ objectData.main.created_at | datetime }}
                    </span>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="!$store.getters.isInconUser"
        color="success"
        small
        @click="acceptObject"
        :disabled="!isProcessable"
        :title="!isProcessable ? 'Статус записи не допускает обработку' : ''"
      >
        <v-icon left small>{{ getStatusIcon(99) }}</v-icon>
        Согласовать
      </v-btn>
      <v-btn
        v-if="!$store.getters.isInconUser"
        :color="getStatusColor(20)"
        small
        @click="rejectObject('')"
        :disabled="!isProcessable"
        :title="!isProcessable ? 'Статус записи не допускает обработку' : ''"
      >
        <v-icon left small>{{ getStatusIcon(20) }}</v-icon>
        {{ getStatusAction(20) }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color small @click="$emit('close')">
        <v-icon left small>mdi-close</v-icon>
        Закрыть
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// Карточка с информацией об объекте. Интерактивные элементы: кнопки отклонения/согласования,
// а также ссылки на уточнение атрибутов. Не показываем их для нерелевантных пользователей,
// либо пользователей Инкон. Те же самые проверки дублируются на сервере
import StatusUtils from '@/mixins/StatusUtils';

export default {
  name: 'object-card',
  mixins: [StatusUtils],
  props: {
    objectData: Object
  },
  data() {
    return {
      tab: 0
    };
  },
  computed: {
    isProcessable() {
      // Перечень статусов, которые допускают обработку записи
      return this.statusesInWork.indexOf(this.objectData.main.stat_id) >= 0;
    },
    toClarifyComment() {
      // Предзаполненный списком ОД для доопределения комментарий
      let valuesToClarify = this.objectData.values_table
        .filter(item => item.valchar === null)
        .map(item => item.dvs_name);

      return valuesToClarify.join(': \n') + ':';
    }
  },
  methods: {
    getAttachmentName(url) {
      // Явное название файла для вложения
      let parts = decodeURI(url).split('/');
      return parts[parts.length - 1];
    },
    acceptObject() {
      this.$emit('acceptObject');
    },
    rejectObject(comment) {
      this.$emit('rejectObject', comment);
    }
  }
};
</script>
