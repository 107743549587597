<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="subtitle-1 font-weight-bold">Материалы для согласования</v-card-title>
      <v-card-subtitle>Выберите класс нижнего уровня для отображения материалов</v-card-subtitle>
    </v-card>
  </v-container>
</template>

<script>
// Плейсхолдер, для еще не выбранного класса
export default {
  name: 'cockpit-placeholder'
};
</script>
