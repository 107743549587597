function b64DecodeUnicode(str) {
  // Декодировка из base64 в юникод, используется для декодирования заголовков запроса,
  // в нашем случае названия файлов, как минимум
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
}

export const downloadBinaryFile = function(response) {
  // Загрузка файла из данных запроса, с декодированием его названия
  let contentHeader = response.headers['content-disposition'];
  let cleanHeader = b64DecodeUnicode(
    contentHeader.replace('=?utf-8?b?', '').replace('?=', '')
  );
  let startIndex = cleanHeader.indexOf('filename=') + 10;
  let endIndex = cleanHeader.length - 1;
  let filename = cleanHeader.substring(startIndex, endIndex);

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename); //or any other extension
  document.body.appendChild(link);
  link.click();
};
