<template>
  <v-main>
    <v-container
      v-if="loading"
      class="d-flex flex-column align-center justify-center"
      style="height: 90vh"
    >
      <v-progress-circular :size="75" color="deep-orange lighten-1" indeterminate></v-progress-circular>
      <p class="deep-orange--text text--lighten-1 mt-10">Загрузка...</p>
    </v-container>
    <v-container fluid :class="{ invisible: loading }">
      <v-row>
        <v-col v-for="item in chartData.overall" :key="item.name">
          <v-card>
            <v-card-text class="text-center">
              <h2 class="title mb-2 grey--text text--darken-3">{{ item.name }}</h2>
              <h1>{{ item.value }}</h1>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Графики -->
      <v-row dense>
        <v-col>
          <v-card>
            <v-card-text>
              <div ref="grouped" class="plotly-chart-placeholder"></div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-text>
              <multiselect
                v-model="approvers.dateChartSelected"
                :options="approvers.list"
                :multiple="true"
                :close-on-select="true"
                label="full_name"
                track-by="id"
                placeholder="Выберите ФИО"
                select-label="Нажмите, чтобы выбрать"
                selected-label="Выбрано"
                deselect-label="Нажмите, чтобы убрать"
                @input="getApproversStatsDate()"
                class="mb-2"
              ></multiselect>
              <div ref="approversStatsDate" class="plotly-chart-placeholder"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-card>
            <v-card-text>
              <v-row class="mb-2 pa-2">
                <v-btn
                  x-small
                  color="deep-orange lighten-1"
                  dark
                  @click="getClassStats()"
                  class="mr-1"
                >На верхний уровень</v-btn>
                <v-btn
                  x-small
                  color="deep-orange lighten-1"
                  dark
                  @click="getClassStats(chartData.classStats.cls_cls_id)"
                >На предыдущий уровень</v-btn>
              </v-row>
              <div ref="classStats" class="plotly-chart-placeholder"></div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="ml-1">
          <v-card>
            <v-card-text>
              <multiselect
                v-model="approvers.overallChartSelected"
                :options="approvers.list"
                :multiple="true"
                :close-on-select="true"
                label="full_name"
                track-by="id"
                placeholder="Выберите ФИО"
                select-label="Нажмите, чтобы выбрать"
                selected-label="Выбрано"
                deselect-label="Нажмите, чтобы убрать"
                @input="getApproversStatsOverall()"
                class="mb-2"
              ></multiselect>
              <div ref="approversStatsOverall" class="plotly-chart-placeholder"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
// Дэшборд, полностью
import api from '@/api';
import Multiselect from 'vue-multiselect';
import Plotly from 'plotly.js-basic-dist';
import locale from 'plotly.js-locales/ru';
Plotly.register(locale);
Plotly.setPlotConfig({ locale: 'ru' });

const STATUS_COLORS = {
  'Отправлено на согласование': '#264e86',
  'Отправлено на согласование': '#ffad83',
  'Повторно отправлено на согласование': '#0074e4',
  'Отправлено на доработку': '#74dbef',
  'Класс на доработке': '#eff0f4',
  'На удаление': 'rgb(255, 127, 14)',
  Согласовано: '#1fb487'
};

const USER_COLORS = [
  '#002851',
  '#466b90',
  '#6a88a6',
  '#adc8dc',
  '#d9ebf9',
  '#154f65',
  '#38697a',
  '#5890a3',
  '#74c0c2',
  '#b9dfe0',
  '#1d4e59',
  '#4c344f',
  '#826c9a',
  '#b698d8',
  '#e9e0f3',
  '#652837',
  '#8d384d',
  '#ca506e',
  '#d98499',
  '#ffc0cb'
];

// Стандартная высота фигуры plotly, из нее будем вычитать высоту
// не plotly элементов (кнопки, дропдауны)
const DEFAULT_HEIGHT = 450;
const MULTISELECT_DROPDOWN_HEIGHT = 52;

export default {
  name: 'dashboard',
  components: { Multiselect },
  data: () => ({
    loading: false,
    approvers: {
      list: [],
      dateChartSelected: [],
      overallChartSelected: []
    },
    chartData: {
      overall: [],
      grouped: [],
      approversStatsDate: {
        personal: [],
        overall: {}
      },
      classStats: [],
      approversStatsOverall: []
    }
  }),
  computed: {},
  methods: {
    toggleLoading() {
      this.loading = !this.loading;
    },
    fetchInitialData() {
      this.toggleLoading();
      Promise.all([
        this.getUserList(),
        this.getOverall(),
        this.getGrouped(),
        this.getApproversStatsDate(),
        this.getClassStats(),
        this.getApproversStatsOverall()
      ]).finally(v => this.toggleLoading());
    },
    getUserList() {
      return api({ method: 'get', url: '/dashboard/approvers' })
        .then(response => {
          this.approvers.list = response.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getOverall() {
      return api({ method: 'get', url: '/dashboard/overall' })
        .then(response => {
          this.chartData.overall = response.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getGrouped() {
      return api({ method: 'get', url: '/dashboard/grouped' })
        .then(response => {
          this.chartData.grouped = response.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getApproversStatsDate() {
      let userIds = [];
      this.approvers.dateChartSelected.forEach(item => {
        userIds.push(item.id);
      });
      return api({
        method: 'get',
        url: '/dashboard/approvers_stats_date',
        params: { user_ids: userIds }
      })
        .then(response => {
          this.chartData.approversStatsDate.personal = response.data.personal;
          this.chartData.approversStatsDate.overall = response.data.overall;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getClassStats(cls_id) {
      return api({
        method: 'get',
        url: '/dashboard/class_stats',
        params: { cls_id: cls_id }
      })
        .then(response => {
          this.chartData.classStats = response.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getApproversStatsOverall() {
      let userIds = [];
      this.approvers.overallChartSelected.forEach(item => {
        userIds.push(item.id);
      });
      return api({
        method: 'get',
        url: '/dashboard/approvers_stats_overall',
        params: { user_ids: userIds }
      })
        .then(response => {
          this.chartData.approversStatsOverall = response.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.$store.dispatch('fetchConfig', 'dashboard');
    this.fetchInitialData();
  },
  watch: {
    'chartData.grouped': {
      handler(newVal) {
        let data = [
          {
            values: Object.values(newVal.obj_statuses),
            labels: Object.keys(newVal.obj_statuses),
            marker: {
              colors: Object.keys(newVal.obj_statuses).map(
                i => STATUS_COLORS[i]
              )
            },
            title: 'Материалы',
            textposition: 'inside',
            domain: { x: [0, 0.5] },
            name: 'Материалы',
            textinfo: 'percent',
            hole: 0.4,
            type: 'pie'
          },
          {
            values: Object.values(newVal.cls_statuses),
            labels: Object.keys(newVal.cls_statuses),
            marker: {
              colors: Object.keys(newVal.cls_statuses).map(
                i => STATUS_COLORS[i]
              )
            },
            title: 'Классы',
            textposition: 'inside',
            domain: { x: [0.5, 1] },
            name: 'Классы',
            textinfo: 'percent',
            hole: 0.4,
            type: 'pie'
          }
        ];

        let layout = {
          title: '<b>В целом по справочнику</b>',
          margin: { t: 40, b: 0, l: 5, r: 5 },
          paper_bgcolor: 'white',
          plot_bgcolor: 'white',
          legend: { orientation: 'h' }
        };

        Plotly.react(this.$refs.grouped, data, layout, {
          displayModeBar: false,
          responsive: true
        });
      },
      deep: true
    },
    'chartData.approversStatsDate': {
      handler(newVal) {
        let data = [];

        newVal.personal.forEach((item, i) => {
          let trace = {
            x: item.dates,
            y: item.counts,
            name: item.user,
            yaxis: 'y1',
            type: 'bar',
            marker: { color: USER_COLORS[i] }
          };
          data.push(trace);
        });

        let layout;

        if (data.length > 0) {
          data.push({
            x: newVal.overall.date,
            y: newVal.overall.total_otn,
            text: newVal.overall.total_abs,
            name: 'Согласовано',
            yaxis: 'y2',
            line: { color: '#d62728' },
            showlegend: true
          });

          layout = {
            title: '<b>Динамика согласования</b>',
            height: DEFAULT_HEIGHT - MULTISELECT_DROPDOWN_HEIGHT,
            hovermode: 'closest',
            barmode: 'stack',
            margin: { t: 25, b: 40, l: 45, r: 40, pad: 4 },
            legend: { x: 1.05, y: 1 },
            yaxis2: {
              titlefont: { color: '#d62728' },
              tickfont: { color: '#d62728' },
              tickformat: '.0%',
              anchor: 'x',
              overlaying: 'y',
              side: 'right',
              zeroline: false,
              showgrid: false,
              range: [0, 1],
              autorange: true
            }
          };
        } else {
          layout = {
            xaxis: { visible: false },
            yaxis: { visible: false },
            margin: { t: 5, b: 40, l: 40, r: 40, pad: 4 },
            annotations: [
              {
                text: 'Данные не найдены',
                xref: 'paper',
                yref: 'paper',
                showarrow: false,
                font: { size: 28 }
              }
            ]
          };
        }

        Plotly.react(this.$refs.approversStatsDate, data, layout, {
          displayModeBar: false,
          responsive: true
        });
      },
      deep: true
    },
    'chartData.classStats': {
      handler(newVal) {
        // Адаптируем высоту, в зависимости от количества баров
        let height = DEFAULT_HEIGHT; // стандартная высота

        if (newVal.class_texts.length > 11) {
          height = 40 * newVal.class_texts.length;
        }
        // Этот график вытянут внизу, поэтому ему ставим overflow-y, а
        // высоту родительского элемента выравниваем до соседней плашки
        this.$refs.classStats.parentElement.style.overflowY = 'scroll';
        this.$refs.classStats.parentElement.style.height =
          DEFAULT_HEIGHT + MULTISELECT_DROPDOWN_HEIGHT + 30 + 'px';

        let trace = {
          x: newVal.percentages,
          y: newVal.class_texts,
          text: newVal.percentages,
          textposition: 'inside',
          marker: {
            color: 'rgb(31, 119, 180)',
            line: { color: '#264e86', width: 1.5 }
          },
          orientation: 'h',
          hoverinfo: 'text',
          hovertext: newVal.hover_texts,
          type: 'bar'
        };

        let layout = {
          title: '<b>По классам верхнего уровня</b>',
          barmode: 'stack',
          height: height,
          margin: { t: 35, l: 390, r: 5, pad: 4 },
          yaxis: {
            type: 'category',
            categoryorder: 'category descending',
            autorange: true,
            zeroline: true
          },
          bargap: 0.35,
          font: {
            'font-weight': 'bold',
            size: 11
          }
        };

        // используем newPlot, чтобы не дублировался обработчик кликов
        Plotly.newPlot(this.$refs.classStats, [trace], layout, {
          displayModeBar: false,
          responsive: true
        });
        // По клику на бар перерисовываем новые данные
        this.$refs.classStats.on('plotly_click', data => {
          let cls_id = newVal.cls_ids[data.points[0].pointIndex];
          this.getClassStats(cls_id);
        });
      },
      deep: true
    },
    'chartData.approversStatsOverall': {
      handler(newVal) {
        let data = [];

        newVal.forEach(item => {
          let trace = {
            x: item.short_names,
            y: item.counts,
            name: item.status,
            marker: { color: STATUS_COLORS[item.status] },
            type: 'bar'
          };
          data.push(trace);
        });

        let layout;
        if (data.length > 0) {
          layout = {
            title: '<b>По пользователям</b>',
            barmode: 'stack',
            margin: { t: 25, b: 40, l: 40, r: 25, pad: -10 },
            height: 'auto',
            legend: { orientation: 'h' },
            xaxis: { tickangle: 0, tickfont: { size: 12 } }
          };
        } else {
          layout = {
            xaxis: { visible: false },
            yaxis: { visible: false },
            margin: { t: 5, b: 40, l: 40, r: 40, pad: 4 },
            annotations: [
              {
                text: 'Данные не найдены',
                xref: 'paper',
                yref: 'paper',
                showarrow: false,
                font: { size: 28 }
              }
            ]
          };
        }

        Plotly.react(this.$refs.approversStatsOverall, data, layout, {
          displayModeBar: false,
          responsive: true
        });
      },
      deep: true
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.plotly-chart-placeholder {
  width: 98%;
}

.invisible {
  opacity: 0;
}

.multiselect__content {
  padding-inline-start: 0px !important;
}
</style>
