<template>
  <v-alert outlined type="warning" border="left">
    Минимальные требования к паролю:
    <ul>
      <li>Длина не менее 8 символов</li>
      <li>Не менее одной буквы</li>
      <li>Не менее одной цифры</li>
    </ul>
  </v-alert>
</template>

<script>
export default {
  name: 'password-rules'
};
</script>
