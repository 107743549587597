<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.maxWidth"
    @keydown.esc="cancel"
    scrollable
    persistent
    :style="{ zIndex: options.zIndex }"
  >
    <v-card @keyup.ctrl.enter="confirmReject">
      <v-card-title>Введите комментарий</v-card-title>
      <v-card-text>
        <v-alert
          v-show="alertHtml.length > 0"
          border="left"
          colored-border
          type="error"
          elevation="2"
        >
          <span v-html="alertHtml"></span>
        </v-alert>
      </v-card-text>
      <v-card-text class="body-1">
        <v-textarea
          outlined
          autofocus
          :placeholder="placeholder"
          :rows="options.textareaRows"
          :counter="options.commentMaxLength"
          :maxlength="options.commentMaxLength"
          hint="Нажмите Ctrl + Enter для быстрой отправки"
          v-model="comment"
          class="font-weight-bold my-1"
        ></v-textarea>
        <v-file-input
          v-model="file"
          dense
          show-size
          solo
          persistent-hint
          hint="Максимальный размер файла: 25 МБ"
          class="font-weight-bold"
          label="Нажмите для прикрепления файла ..."
          accept=".pdf, .xls, .xlsx, .doc, .docx, .zip, .rar, .7z, .jpg, .jpeg, .png, .svg, .tiff, .bmp"
        ></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-btn color="warning" small @click="confirmReject" :disabled="!comment.length">
          <v-icon left small>mdi-email</v-icon>Отправить
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="warning" small @click="cancel">
          <v-icon left small>mdi-close</v-icon>Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Диалоговое окно для отклонения объектов/классов.
// Содержит поле ввода текста и прикрепления файла.
// Открывается из внешнего кода прямым вызовом метода .open() и обрабатывается
// как промис
import StatusUtils from '@/mixins/StatusUtils';

export default {
  name: 'rejection-dialog',
  mixins: [StatusUtils],
  data: () => ({
    dialog: false,
    comment: '',
    placeholder: '',
    alertHtml: '',
    file: null,
    options: {
      maxWidth: '30%',
      textareaRows: 6,
      commentMaxLength: 1500,
      zIndex: 999
    }
  }),
  methods: {
    open(placeholder, comment, alertHtml, options) {
      this.dialog = true;
      this.placeholder = placeholder;
      this.comment = comment;
      this.alertHtml = alertHtml;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    clear() {
      this.comment = '';
      this.file = null;
    },
    confirmReject() {
      if (!this.comment.length) {
        this.$notify.error('Отправка без комментария невозможна');
        return;
      }

      const rejectionData = {
        comment: this.comment,
        file: this.file
      };
      this.resolve(rejectionData);
      this.clear();
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.clear();
      this.dialog = false;
    }
  }
};
</script>
