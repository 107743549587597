// Фильтры для интерполяции даты/времени в шаблонах
function formatDate(value, options) {
  // Форматирование по умолчанию
  options = options || {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  };
  const LOCALE = navigator.language || navigator.userLanguage;
  return new Intl.DateTimeFormat(LOCALE, options)
    .format(new Date(value))
    .replace(/\s*г\./, ''); // В российской локали в год прописывается " г."
}

// Стандартный День-Месяц-Год
export const dateFilter = function(value) {
  return formatDate(value);
};

// Дата + время
export const dateTimeFilter = function(value) {
  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  return formatDate(value, options);
};
