<template>
  <v-card>
    <v-card-title>
      Класс {{ classData.main.code }} - {{ classData.main.name }}
      <v-icon
        class="mx-2"
        v-if="classData.main.class_is_busy"
        v-text="getStatusIcon(classData.main.stat_id)"
        :color="getStatusColor(classData.main.stat_id)"
        :title="getStatusTitle(classData.main.stat_id)"
      ></v-icon>
      <v-spacer></v-spacer>
      <v-icon @click="$emit('close')">mdi-close</v-icon>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="openedTab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-if="classData.main.is_leaf_node">
          <v-icon left>mdi-view-list</v-icon>
          Атрибутика
        </v-tab>
        <v-tab>
          <v-icon left>mdi-archive</v-icon>
          История согласования
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="openedTab">
        <v-tab-item v-if="classData.main.is_leaf_node">
          <v-card class="ma-1">
            <v-card-title>
              <v-icon left>mdi-file-code-outline</v-icon>
              Шаблон
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="3" class="py-1 subtitle-1 font-weight-medium">
                  Краткое наименование
                </v-col>
                <v-col class="py-1">
                  {{ classData.main.template_short_name }}
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3" class="py-1 subtitle-1 font-weight-medium">
                  Полное наименование
                </v-col>
                <v-col class="py-1">
                  {{ classData.main.template_full_name }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="ma-1">
            <v-card-title>
              <v-icon left>mdi-scale-balance</v-icon>
              Единица измерения
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="3" class="py-1 subtitle-1 font-weight-medium">
                  Единица измерения
                </v-col>
                <v-col class="py-1">{{ classData.main.norm_cum }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row dense>
            <v-col md="6">
              <v-card class="ma-1">
                <v-card-title>
                  <v-icon left>mdi-format-list-bulleted</v-icon>
                  Атрибуты
                </v-card-title>
                <template v-if="classData.class_attributes.length">
                  <v-card-subtitle>
                    Кликните для просмотра значений
                  </v-card-subtitle>
                  <v-card-text>
                    <v-simple-table
                      dense
                      ref="charTable"
                      :height="dataTableHeight"
                    >
                      <template #default>
                        <tbody class="selectable">
                          <tr
                            v-for="item in classData.class_attributes"
                            :key="item.dvs_id"
                            @click="setAttribute(item)"
                            class="pointered"
                            :class="{
                              highlight:
                                item.dvs_id === selectedAttribute.dvs_id
                            }"
                          >
                            <td>
                              {{ item.dvs_name }}
                              <v-icon
                                v-if="item.mandatory"
                                x-small
                                title="Обязательный атрибут"
                              >
                                mdi-star
                              </v-icon>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </template>
                <v-card-subtitle v-else>Атрибуты отсутствуют</v-card-subtitle>
              </v-card>
            </v-col>
            <v-col md="6">
              <v-card class="ma-1">
                <v-card-title>
                  <v-icon left>mdi-format-list-bulleted</v-icon>
                  Значения атрибута
                </v-card-title>
                <template v-if="classData.class_attributes.length">
                  <v-card-subtitle>
                    {{
                      selectedAttribute
                        ? selectedAttribute.dvs_name
                        : 'Выберите атрибут'
                    }}
                  </v-card-subtitle>
                  <v-card-text>
                    <v-simple-table dense :height="dataTableHeight">
                      <template #default>
                        <tbody class="stripped">
                          <tr v-for="item in valuesList" :key="item.vsn_id">
                            <td>{{ item.valchar }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </template>
                <v-card-subtitle v-else>Атрибуты отсутствуют</v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-timeline align-top dense>
            <v-timeline-item
              v-for="comment in classData.class_comments"
              :key="comment.com_cls_id"
              :color="getStatusColor(comment.stat_id)"
              :icon="getStatusIcon(comment.stat_id)"
              fill-dot
            >
              <v-card class="mx-1">
                <v-card-title>
                  <v-row>
                    <p class="ml-2">
                      <v-icon>mdi-message-text-outline</v-icon>
                      {{ comment.user_name }}
                    </p>
                    <v-spacer></v-spacer>
                    <p class="overline mr-2">
                      {{ comment.created_at | datetime }}
                    </p>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <p class="body-1">{{ comment.com_text }}</p>
                  <v-divider></v-divider>
                  <p class="font-weight-bold my-2">
                    {{ comment.old_status_name }}
                    <v-icon small>mdi-arrow-right</v-icon>
                    {{ comment.new_status_name }}
                  </p>

                  <div v-if="comment.attachment_url">
                    <v-btn
                      text
                      color="primary"
                      :href="comment.attachment_url"
                      download
                      title="Вложение"
                    >
                      <v-icon left>mdi-attachment</v-icon>
                      {{ getAttachmentName(comment.attachment_url) }}
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-timeline-item>
            <!-- Фиктивная точка с датой создания класса -->
            <v-timeline-item
              :color="getStatusColor(0)"
              :icon="getStatusIcon(0)"
              fill-dot
            >
              <v-card>
                <v-card-text>
                  <v-row class="pa-2" align="center">
                    <v-icon left>mdi-state-machine</v-icon>
                    Класс направлен на согласование
                    <v-spacer></v-spacer>
                    <span class="overline">
                      {{ classData.main.created_at | datetime }}
                    </span>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions>
      <v-menu
        v-if="!$store.getters.isInconUser && classData.main.user_relevant"
        offset-y
        transition="slide-x-transition"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :disabled="classData.main.class_is_busy"
            :title="
              classData.main.class_is_busy
                ? 'Замечание к классу уже выдано'
                : ''
            "
            small
            :color="getStatusColor(20)"
          >
            <v-icon left small>{{ getStatusIcon(20) }}</v-icon>
            {{ getStatusAction(20) }}
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="option in processingOptions"
            :key="option.action"
            @click="rejectClass(option)"
            v-show="classData.main.is_leaf_node || option.relevantToParent"
          >
            <v-btn
              block
              text
              tile
              x-small
              :color="
                option.objectsRejected ? getStatusColor(25) : getStatusColor(20)
              "
            >
              <v-list-item-title>{{ option.name }}</v-list-item-title>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <v-btn color small @click="$emit('close')">
        <v-icon left small>mdi-close</v-icon>
        Закрыть
      </v-btn>
    </v-card-actions>
    <RejectionDialog ref="reject" />
  </v-card>
</template>

<script>
// Карточка с информацией о классе и кнопкой отклонения.
// Кнопка скрывается в зависимости от параметров пользователя:
// не показываем ее для нерелевантных пользователей, либо пользователей Инкон.
// Те же самые проверки дублируются на сервере
import StatusUtils from '@/mixins/StatusUtils';
import RejectionDialog from '@/components/dialog/RejectionDialog';

export default {
  name: 'class-card',
  mixins: [StatusUtils],
  components: { RejectionDialog },
  props: {
    classData: Object
  },
  data: () => ({
    processingOptions: [
      {
        action: 'change_class_name',
        name: 'Изменить наименование класса',
        objectsRejected: false,
        relevantToParent: true
      },
      {
        action: 'change_class_hierarchy',
        name: 'Переместить класс',
        objectsRejected: false,
        relevantToParent: true
      },
      {
        action: 'change_class_template',
        name: 'Изменить шаблон',
        objectsRejected: true,
        relevantToParent: false
      },
      {
        action: 'divide_or_combine_class',
        name: 'Разделить/Объединить класс',
        objectsRejected: true,
        relevantToParent: true
      },
      {
        action: 'class_comment_free',
        name: 'Другое',
        objectsRejected: true,

        relevantToParent: true
      }
    ],
    dialogOptions: {
      maxWidth: '40%',
      textareaRows: 9,
      commentMaxLength: 1500,
      zIndex: 999
    },
    openedTab: 0,
    selectedAttribute: '',
    dataTableHeight: null // Считаем фактическую высоту динамически при обновлении данных
  }),
  computed: {
    valuesList() {
      // Список значений по выбранному dvs_id, для интерактивности
      return this.classData.attribute_values.filter(
        val => val.dvs_id === this.selectedAttribute.dvs_id
      );
    },
    rejectDialog() {
      return this.$refs.reject;
    }
  },
  methods: {
    getInitialComment(action) {
      // Предзаполнение комментария, там где это уместно
      let comment = '';
      switch (action) {
        case 'change_class_name':
          comment = this.classData.main.name;
          break;
        case 'change_class_template':
          comment =
            `Краткое наименование: ${this.classData.main.template_short_name}\n\n` +
            `Полное наименование: ${this.classData.main.template_full_name}`;
          break;
      }
      return comment;
    },
    getAlertHtml(option) {
      // Отображение предупреждающей плашки для изменений, затрагивающих
      // статусы записей классов. Отдельное предупреждение при отклонении
      // классов-родителей
      if (!option.objectsRejected) {
        return '';
      }

      if (this.classData.main.is_leaf_node) {
        return (
          `При выполнении действия <i>"${option.name}"</i> <strong>ВСЕ</strong> ` +
          'записи текущего класса перейдут в статус "Класс на доработке"'
        );
      }

      return (
        '<b>Внимание!</b> Вы собираетесь отправить ' +
        'на доработку верхний класс со всеми нижестоящими классами и материалами. ' +
        'Данное действие нельзя отменить.'
      );
    },
    rejectClass(option) {
      // Проверяет класс, если препятствий нет - открывает диалог отклонения и
      // ожидает его обработки как промиса в .then
      if (this.classData.main.class_is_busy) {
        this.$notify.error('Замечание к классу уже выдано');
        return;
      }

      let alertHtml = this.getAlertHtml(option);

      this.rejectDialog
        .open(
          option.name,
          this.getInitialComment(option.action),
          alertHtml,
          this.dialogOptions
        )
        .then(data => {
          // Изменения отправляются на сервер
          if (data) {
            let meta = {
              cls_action: option.action,
              comment: data.comment
            };

            let payload = new FormData();

            if (data.file) {
              payload.append('file', data.file, data.file.name);
            }
            payload.append('meta', JSON.stringify(meta));

            this.$store
              .dispatch('updateClass', {
                cls_id: this.classData.main.cls_id,
                data: payload
              })
              .then(response => {
                this.$emit('close');
                // Обновление таблицы
                if (option.objectsRejected) {
                  // Вместо изменения статусов в таблице просто запросим
                  // данные с сервера
                  this.$store.dispatch('fetchObjects');
                }
                // Обновление статусов пользователя
                this.$store.dispatch('fetchUserStatusCounts');
                // Обновление метаданных класса(ов)
                this.$store.dispatch(
                  'getClassMeta',
                  this.classData.main.cls_id
                );
                this.$notify.success(response.data.message);
              })
              .catch(error => {
                this.$notify.error(error.response.data.message);
              });
          }
        });
    },
    setAttribute(attr) {
      this.selectedAttribute = attr;
    },
    getAttachmentName(url) {
      // Имя файла выводится в явном виде
      let parts = decodeURI(url).split('/');
      return parts[parts.length - 1];
    },
    computeDataTableHeight() {
      // Динамическое вычисление высоты таблиц по количеству признаков
      // Использует refs, поэтому не подходит для computed без костылей.
      // к тому же вычисляется только один раз при открытии окна
      let charTable = this.$refs.charTable;

      // Если таблицы нет, выходим
      if (!charTable) {
        return;
      }

      let minHeight = 100;
      this.$nextTick(() => {
        this.dataTableHeight = Math.max(minHeight, charTable.$el.offsetHeight);
      });
    }
  },
  mounted() {
    this.computeDataTableHeight();
  }
};
</script>

<style lang="scss">
.pointered {
  cursor: pointer;
}

tbody.stripped tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

tbody.selectable {
  tr {
    :hover {
      background-color: $row-hover-color;
    }

    &.highlight {
      background-color: $selected-row-color;

      :hover {
        background-color: $selected-row-hover-color;
      }
    }
  }
}
</style>
