<template>
  <v-container fluid>
    <v-card class="mb-2" min-width="60vw">
      <v-card-title class="py-2 subtitle-1 font-weight-bold">
        {{ classData.code }} - {{ classData.name }}
        <v-tooltip
          right
          v-if="classData.class_is_busy"
          max-width="300"
          :color="getStatusColor(classData.stat_id)"
        >
          <template #activator="{ on, attrs }">
            <v-icon
              class="mx-2"
              v-text="getStatusIcon(classData.stat_id)"
              :color="getStatusColor(classData.stat_id)"
              :title="getStatusTitle(classData.stat_id)"
              @click.stop
              v-bind="attrs"
              v-on="on"
            ></v-icon>
          </template>
          <template #default>
            <div v-if="classData.commentCategory">
              <p class="font-weight-bold my-1">
                {{ classData.commentCategory }}
              </p>
              <p>{{ classData.commentText }}</p>
            </div>
            <div v-else>
              <p>Комментарий отсутствует</p>
            </div>
          </template>
        </v-tooltip>
        <v-btn
          v-bind="size"
          outlined
          class="ml-3"
          color="grey darken-1"
          @click="openClassCard(classData.cls_id)"
        >
          <v-icon left small color="info">mdi-information</v-icon>
          Подробно
        </v-btn>
        <v-spacer></v-spacer>
        <v-menu
          v-if="availableExcelExport || availableReportExport"
          offset-y
          transition="slide-x-transition"
        >
          <template #activator="{ on }">
            <v-btn v-on="on" v-bind="size">
              <v-icon left small>mdi-file-excel</v-icon>
              Файлы
            </v-btn>
          </template>
          <v-list dense>
            <template v-if="availableExcelExport">
              <v-subheader class="ml-3">Массовая обработка</v-subheader>
              <v-list-item @click="excelExport()">
                <v-list-item-title>Выгрузить в таблицу Excel</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$refs.excel.click()">
                <v-list-item-title>
                  <input
                    type="file"
                    ref="excel"
                    style="display:none"
                    @change="excelImport"
                  />
                  Загрузить из таблицы Excel
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-if="availableReportExport">
              <v-divider v-if="availableExcelExport"></v-divider>
              <v-list-item @click="reportExport()">
                <v-list-item-title>Сформировать отчет</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col
            align-self="center"
            class="py-1"
            md="7"
            v-if="
              !$store.getters.isInconUser &&
                classData.user_relevant &&
                tableData.length > 0
            "
          >
            <v-menu offset-y transition="slide-x-transition">
              <template #activator="{ on }">
                <v-btn v-bind="size" v-on="on" class="mx-2">
                  <v-icon left small>mdi-checkbox-marked</v-icon>
                  Выделить
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="item in selectionOptions"
                  :key="item.code"
                  @click="selectRecords(item.code)"
                >
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              v-bind="size"
              :color="getStatusColor(99)"
              @click="acceptObjects"
              class="mx-2"
            >
              <v-icon left small>{{ getStatusIcon(99) }}</v-icon>
              {{ getStatusAction(99) }}
            </v-btn>
            <v-btn
              v-bind="size"
              :color="getStatusColor(20)"
              @click="rejectObjects('')"
              class="mx-2"
            >
              <v-icon left small>{{ getStatusIcon(20) }}</v-icon>
              {{ getStatusAction(20) }}
            </v-btn>
          </v-col>
          <v-col align-self="center" class="py-1">
            <v-row>
              <v-switch
                dense
                hide-details
                v-for="filter in availableSwitches"
                :key="filter.name"
                :label="filter.label"
                color="success"
                v-model="filter.value"
                class="small-input mx-2 my-0"
              ></v-switch>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card :height="appHeight" min-width="60vw">
      <div ref="objects" :id="TABLE_ID" class="my-0"></div>
    </v-card>
    <v-dialog
      v-model="showObjectCard"
      max-width="60%"
      @keydown.esc="showObjectCard = false"
      scrollable
      persistent
    >
      <ObjectCard
        :objectData="$store.state.object.data"
        @close="showObjectCard = false"
        @acceptObject="acceptObjects"
        @rejectObject="rejectObjects"
      />
    </v-dialog>

    <ConfirmationDialog ref="confirm" />
    <RejectionDialog ref="reject" />

    <v-snackbar light v-model="showReport" :timeout="reportTimeout">
      <v-container>
        <v-row
          class="my-2"
          v-for="item in Object.entries(lastReport)"
          :key="item[0]"
        >
          <b>{{ item[0] }}:</b>
          <v-spacer></v-spacer>
          <i class="mx-4">{{ item[1] }}</i>
        </v-row>
      </v-container>
      <template #action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="showReport = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
// Основной воркспейс - таблица с материалами. Возможно тут стоит побить на компоненты,
// так как он достаточно большой уже.
// Основная загвоздка - tabulator.js (табличный плагин), не интегрируется со VueJS безшовно,
// так как для него автором не создан vue компонент.
// поэтому некоторые вещи (например подвал таблицы, контекстное меню) делаются напрямую через DOM
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import {
  selectionOptions,
  switchFilters,
  getTabulator,
  TABLE_ID
} from '@/helpers/tabulator';
import StatusUtils from '@/mixins/StatusUtils';
import ObjectCard from '@/components/main/ObjectCard';
import ConfirmationDialog from '@/components/dialog/ConfirmationDialog';
import RejectionDialog from '@/components/dialog/RejectionDialog';

export default {
  name: 'objects-table',
  mixins: [StatusUtils],
  components: { ObjectCard, ConfirmationDialog, RejectionDialog },
  props: {
    classData: Object
  },
  data: () => ({
    TABLE_ID: TABLE_ID,
    tabulator: null,
    selectionOptions: selectionOptions,
    switchFilters: switchFilters,
    customFooterId: 'custom-footer',
    tableStats: {
      isFiltered: false,
      count: 0,
      filtered: 0,
      selected: {
        all: 0,
        nontargets: 0
      }
    },
    tableSelection: {
      rows: [],
      count: 0,
      validCount: 0
    },
    showObjectCard: false,
    showReport: false,
    reportTimeout: 10000,
    lastReport: ''
  }),
  computed: {
    appHeight() {
      // Динамическая высота таблицы, считаем от размера экрана
      // offset - разница между целевой высотой таблицей и выотой
      // экрана, то есть это сумма высоты шапки, карточки с кнопками/свитчами,
      // и всех отступов
      let offset;

      // Для пользователей ИНКОН отспуп одинаковый, так как кнопок нет
      if (this.$store.getters.isInconUser) {
        offset = 165;
      } else {
        offset = {
          xs: 185,
          sm: 185,
          md: 185,
          lg: 185,
          xl: 176
        }[this.$vuetify.breakpoint.name];
      }
      return `calc(100vh - ${offset}px)`;
    },
    size() {
      // Адаптивные размеры кнопок, в зависимости отразмера экрана
      const size = {
        xs: 'x-small',
        sm: 'x-small',
        md: 'x-small',
        lg: 'x-small',
        xl: 'small'
      }[this.$vuetify.breakpoint.name];
      return size ? { [size]: true } : {};
    },
    tableData() {
      // Табличные данные обновляются в хранилище
      return this.$store.state.table.tableData;
    },
    tableFooter() {
      // Формирование подвала с информацией для таблицы, в зависимости от tableStats
      // tableStats пересчитываются в коллбэках таблицы (см.файл tabulator.js) и передаются в
      // данные компонента
      let selectedCountsText = `Выбрано: ${this.tableStats.selected.all}`;

      if (this.tableStats.selected.nontargets) {
        selectedCountsText += ` (<b>нецелевых: ${this.tableStats.selected.nontargets})</b>`;
      }

      let selectedElement = `<span style="float:left" class="ml-8 px-2 py-1">${selectedCountsText}</span>`;
      let filteredElement = `<span style="float:left" class="px-2 py-1">В фильтре: ${this.tableStats.filtered}</span>`;
      let countElement = `<span class="mx-2 py-1">Всего записей: <b>${this.tableStats.count}</b> </span>`;

      let footerElement = '';

      if (this.tableStats.selected.all > 0) {
        footerElement += selectedElement;
      }

      if (this.tableStats.isFiltered) {
        footerElement += filteredElement;
      }

      // Обязательная подклейка общего количества
      footerElement += countElement;

      return footerElement;
    },
    confirmDialog() {
      return this.$refs.confirm;
    },
    rejectDialog() {
      return this.$refs.reject;
    },
    availableSwitches() {
      if (this.$store.getters.isInconUser) {
        return this.switchFilters;
      } else {
        return this.switchFilters.filter(flt => !flt.hideFromClient);
      }
    },
    availableExcelExport() {
      return (
        !this.$store.getters.isInconUser &&
        this.classData.user_relevant &&
        this.tableData.length > 0
      );
    },
    availableReportExport() {
      return this.$store.getters.isSuperOrInconUser;
    }
  },
  watch: {
    tableData: {
      // при получении новых данных (выбор другого класса), обновляем таблицу
      handler(newData) {
        if (newData.length > 0) {
          this.tabulator.replaceData(newData);
        } else {
          this.tabulator.clearData();
        }
      },
      deep: true,
      immedate: true
    },
    availableSwitches: {
      // Отслеживает изменение "крыжиков" и накладывает соответствующие фильтры на таблицу
      handler(newFilters) {
        let filtersToApply = [];
        newFilters.forEach(f => {
          if (f.value) {
            filtersToApply.push(f.filter);
          }
        });
        this.tabulator.setFilter(filtersToApply);
      },
      deep: true
    },
    tableStats: {
      // Если табулятор обновляет нам статистику, мы перерисовываем его футер
      handler(newStats) {
        document.getElementById(
          this.customFooterId
        ).innerHTML = this.tableFooter;
      },
      deep: true
    }
  },
  methods: {
    validateObjects() {
      // Базовая валидация при выполнении действия над объектами
      // Если ничего не выбрали: пушим предупреждение и прерываемся
      if (!this.tableSelection.count) {
        this.$notify.warning('Не выбрано ни одной записи');
        return false;
        // Если среди выбранных нет валидных статусов - тоже выходим.
      } else if (this.tableSelection.validCount === 0) {
        // Если среди выбраных нет валидных прерываемся
        this.$notify.warning('Нет статусов для обработки');
        return false;
      }

      return true;
    },
    updateObjects({ comment, action, file }) {
      // Основная функция обновления объектов
      let objects = [];

      // Выкинем на уровне фронтэнда нерелевантные позиции
      this.tableSelection.rows.forEach(row => {
        let rowdata = row.getData();
        if (this.statusesInWork.indexOf(rowdata.stat_id) >= 0) {
          objects.push({
            obj_id: rowdata.obj_id
          });
        }
      });

      const meta = {
        comment: comment,
        records: objects,
        action: action,
        cls_id: this.classData.cls_id
      };

      let payload = new FormData();
      // В запросе улетает и файл, и остальные данные
      if (file) {
        payload.append('file', file, file.name);
      }
      payload.append('meta', JSON.stringify(meta));

      this.$store
        .dispatch('updateObjects', payload)
        .then(response => {
          this.showObjectCard = false; // Если согласуем из карточки - закрываем
          // Обновляем таблицу
          this.refreshTable(response.data);
          // Обновляем статусы
          this.$store.dispatch('fetchUserStatusCounts');
          // Обновляем метаданные классов
          this.$store.dispatch('getClassMeta', this.classData.cls_id);
          this.$notify.success(response.data.message);
        })
        .catch(error => {
          this.$notify.error(error.response.data.message);
        });
    },
    acceptObjects() {
      // Согласование объектов. Вызывается как из формы с таблицей, так
      // и из модального окна. Если будут еще какие-то кейсы,
      // они тоже должны вызывать эту функцию.

      // Валидация
      if (!this.validateObjects()) {
        return;
      }
      const targetStatus = 99;
      // Набиваем нагрузку константами, для соответствия старому серверному коду
      const payload = { comment: 'Согласовано', action: '1', file: null };

      // Если все записи корректны, то согласуем без подтверждения,
      // иначе - выводим модалку с подтверждением
      if (this.tableSelection.count === this.tableSelection.validCount) {
        this.updateObjects(payload);
      } else {
        this.confirmDialog
          .open(
            targetStatus,
            this.tableSelection.count,
            this.tableSelection.validCount
          )
          .then(confirm => {
            if (confirm) {
              this.updateObjects(payload);
            }
          });
      }
    },
    async rejectObjects(initialComment) {
      // Аналогичный метод для отправки объектов на доработку
      // Отклонение работает через RejectionDialog в любом случае,
      // так как требуется комментарий
      if (!this.validateObjects()) {
        return;
      }
      if (!initialComment) {
        initialComment = '';
      }

      const targetStatus = 20;
      let confirmed = true;

      if (this.tableSelection.count !== this.tableSelection.validCount) {
        confirmed = await this.confirmDialog.open(
          targetStatus,
          this.tableSelection.count,
          this.tableSelection.validCount
        );
      }

      if (confirmed) {
        this.rejectDialog
          .open('Введите комментарий по отклонению', initialComment, '')
          .then(data => {
            if (data) {
              const payload = {
                comment: data.comment,
                file: data.file,
                action: '0'
              };
              this.updateObjects(payload);
            }
          });
      }
    },
    refreshTable(data) {
      // Обновляем отправленные записи - ставим новые статусы
      this.tableSelection.rows.forEach(row => {
        let status = row.getData().stat_id;
        if (this.statusesInWork.indexOf(status) >= 0) {
          row.update({ stat_id: data.new_status });
        }
      });
      this.tabulator.deselectRow();
    },
    openClassCard(cls_id) {
      // Открытие карточки класса. Это можно сделать как здесь, так и из контекстного меню дерева
      this.$store
        .dispatch('getClassInfo', cls_id)
        .then(_ => this.$store.commit('CLASS_MODAL', true));
    },
    selectRecords(code) {
      // Управление выделением записей по кнопке "выделить"
      switch (code) {
        case 'all':
          this.tabulator.selectRow('active'); // Выделяется все что попадает в фильтры
          break;
        case 'page':
          this.tabulator.deselectRow();
          // this.tabulator.selectRow('visible'); // Только видимык объекты, может подложить свинью, если страница имеет прокрутку
          // Встроенного метода в табуляторе нет, поэтому здесь используется НЕПУБЛИЧНОЕ API.
          // Работоспособность этого блока необходимо проверять при обновлении версии tabulator.js
          this.tabulator.rowManager
            .getDisplayRows()
            .forEach(row => row.getComponent().select());
          break;
        case 'drop':
          this.tabulator.deselectRow();
          break;
      }
    },
    excelExport() {
      // Если класс отклонен, не даем выгрузить, чтобы "оградить пользователя от лишней работы"
      if (this.classData.stat_id === 25) {
        this.$notify.error(
          'Класс находится на доработке у эксперта. Выгрузка временно недоступна.'
        );
        return;
      }
      // Выгрузка в Excel всего, что отфильтровано. Выделение записей не играет роли
      let objects = [];

      this.tabulator.getRows('active').forEach(row => {
        objects.push(row.getData().obj_id);
      });

      let payload = {
        cls_id: this.classData.cls_id,
        objects: objects
      };

      this.$store
        .dispatch('excelDownload', payload)
        .then(_ => {
          this.$notify.success('Файл сформирован');
        })
        .catch(_ => {
          this.$notify.error('Ошибка при формировании файла');
        });
    },
    excelImport(event) {
      // Загрузка обработанного файла Excel и обновление состояние приложения
      // Обновленный класс получаем с сервера, на случай, если мы загружали
      // файл из какого-то другого класса, отличного от открытого. По обновленному
      // классу получим статистику и обновим дерево
      let formData = new FormData();
      formData.append('file', event.target.files[0]);
      this.$store
        .dispatch('excelUpload', formData)
        .then(response => {
          this.lastReport = response.data.report;
          this.showReport = true;

          this.$store.dispatch('fetchObjects');
          this.$store.dispatch('fetchUserStatusCounts');
          this.$store.dispatch('getClassMeta', response.data.updated_cls_id);
          this.$notify.success('Файл успешно загружен');
        })
        .catch(error => {
          this.$notify.error(error.response.data.message);
        })
        .finally(_ => (this.$refs.excel.value = ''));
    },
    reportExport() {
      let payload = {
        cls_id: this.classData.cls_id
      };

      this.$store
        .dispatch('reportDownload', payload)
        .then(_ => {
          this.$notify.success('Файл сформирован');
        })
        .catch(_ => {
          this.$notify.error('Ошибка при формировании файла');
        });
    }
  },
  mounted() {
    // Инициализируем табулятор при монтировании
    this.tabulator = getTabulator(this);
  }
};
</script>

<style lang="scss">
// Стилизация табулятора
.tabulator {
  margin-top: 0.2rem !important;
}
.tabulator-tableHolder {
  font-size: 14px;
  font-weight: 300;
}
// Ячейки в шапке
.tabulator-col {
  border-right: 1px solid #e6e6e6 !important;
}
// Данные в шапке
.tabulator-col-content {
  padding: 0.3em 0.3em 0.3em 0.3em !important;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}
// Фильтры заголовка
.tabulator-header-filter input {
  font-size: 14px;
  font-weight: 300;
  border-radius: 1em;
  height: 1.3em;
  border: none;
  box-shadow: 0 0.1rem 1rem 0 rgba(0, 0, 0, 0.1);
}

// Ячейки остальной таблицы
.tabulator-cell {
  padding: 0.3em 0.3em 0.3em 0.3em !important;
  border-right: 1px solid #e6e6e6 !important;

  &.tabulator-frozen {
    padding: 0.1em 0.1em 0.1em 0.1em !important;
  }
}

// Ховер по строкам
.tabulator-row.tabulator-selectable:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.87) inset;
  background: $row-hover-color !important;
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: pointer;
}

.tabulator-row.tabulator-selectable.tabulator-selected:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.87) inset;
  background: $selected-row-hover-color !important;
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: pointer;
}

.tabulator-row.tabulator-selected {
  background-color: $selected-row-color !important;
}

.tabulator-row {
  background: white;
}

.tabulator-row.tabulator-row-even {
  background-color: $even-row-color;
}

// Цвет активной страницы
.tabulator .tabulator-footer .tabulator-page.active {
  background-color: $info-background-color;
  color: $info-color;
}
// Плейсхолдер сдвинем влево
.tabulator .tabulator-tableHolder .tabulator-placeholder span {
  margin: 20px;
  align-self: baseline;
}
// Меню. Никакие стили и классы vuetify не применяются, потому что
// меню рендерится за пределами v-app
.tabulator-header-menu-button {
  font-weight: 900;
}

.tabulator-menu {
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 500;

  .tabulator-menu-item {
    line-height: 1.5;

    .checked {
      color: #4caf50 !important;
      caret-color: #4caf50 !important;
    }

    i {
      font-size: 18px;
    }
  }
}
</style>
