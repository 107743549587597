<template>
  <div>
    <v-menu>
      <template #activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">Прогресс согласования: {{ percent }}%</v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(item, index) in statusCounts"
          :key="index"
          v-show="item.status_name !== headerName"
        >
          <v-list-item-title>{{ item.status_name }}</v-list-item-title>
          <v-list-item-action>
            <span class="body-2 font-weight-bold">{{ item.status_count }}</span>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
// Плашка (меню в шапке) со статистикой пользователя
export default {
  name: 'user-stats',
  data: () => ({
    headerName: 'Процент'
  }),
  computed: {
    percent() {
      let overallStatus = this.statusCounts.find(
        i => i.status_name === this.headerName
      );
      if (overallStatus) {
        return overallStatus.status_count;
      } else {
        return 0;
      }
    },
    statusCounts() {
      return this.$store.state.statusCounts;
    }
  }
};
</script>
