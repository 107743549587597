<template>
  <v-app-bar app clipped-left extension-height="0" color="blue lighten-5" elevation="3" height="55">
    <v-app-bar-nav-icon>
      <v-img class="ml-3" src="/static/img/app_logo.png" width="40px"></v-img>
    </v-app-bar-nav-icon>
    <v-toolbar-title>Согласование материалов</v-toolbar-title>
    <v-spacer></v-spacer>

    <template v-if="this.$store.getters.isSuperOrInconUser">
      <v-btn
        small
        color="deep-orange lighten-1"
        dark
        v-if="this.$route.name !== 'dashboard'"
        :to="{name: 'dashboard'}"
      >
        <v-icon left>mdi-chart-donut-variant</v-icon>Перейти в Дэшборд
      </v-btn>
      <v-btn small color="deep-orange lighten-1" dark v-else :to="{name: 'main'}">
        <v-icon left>mdi-handshake</v-icon>Перейти к согласованию
      </v-btn>
    </template>

    <UserStats v-else />

    <v-spacer></v-spacer>
    <div class="body-1 font-weight-bold text--secondary mx-3">
      <v-icon left>mdi-account</v-icon>
      <span>{{ firstName }}</span>
    </div>

    <v-btn small dark color="deep-orange lighten-1" @click="logout">
      <v-icon left>mdi-exit-to-app</v-icon>Выход
    </v-btn>

    <v-progress-linear
      :active="$store.state.isLoading"
      :indeterminate="true"
      class="ma-0"
      slot="extension"
    />
  </v-app-bar>
</template>

<script>
// Панель навигации для основных экранов приложения
// Из логики только отображение либо статусов пользователя, либо кнопки перехода в Дэшборд.
// Для дэшборда, наоборот, показываем кнопку перехода в контент.
// Также здесь имя пользователя и кнопка выхода из системы
import UserStats from '@/components/widgets/UserStats';

export default {
  name: 'navbar',
  components: { UserStats },
  computed: {
    firstName() {
      return this.$store.state.config.user.first_name;
    }
  },
  methods: {
    logout() {
      this.$store
        .dispatch('logout')
        .then(response => {
          this.$notify.success(response.data.message);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
