<template>
  <v-dialog v-model="dialog" max-width="25%" @keydown.esc="cancel" scrollable persistent>
    <v-card>
      <v-card-title>
        Сменить пароль
        <v-spacer></v-spacer>
        <v-icon @click="cancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <PasswordRules />
        <v-form v-model="formIsValid">
          <v-text-field
            v-model="user.username"
            light
            required
            :rules="fieldRules"
            prepend-icon="mdi-account"
            label="Имя пользователя"
            type="text"
            autocomplete="username"
            :color="color"
            @keyup.enter="save"
          ></v-text-field>
          <v-text-field
            v-model="user.password"
            light
            required
            :rules="fieldRules"
            prepend-icon="mdi-lock"
            label="Пароль"
            type="password"
            autocomplete="current-password"
            :color="color"
            @keyup.enter="save"
          ></v-text-field>
          <v-text-field
            v-model="user.newPassword"
            light
            required
            :rules="fieldRules"
            prepend-icon="mdi-form-textbox-password"
            label="Новый пароль"
            type="password"
            autocomplete="current-password"
            :color="color"
            @keyup.enter="save"
          ></v-text-field>
          <v-text-field
            v-model="user.newPasswordRepeated"
            light
            required
            :rules="fieldRules"
            prepend-icon="mdi-form-textbox-password"
            label="Подтвердите новый пароль"
            type="password"
            autocomplete="current-password"
            :color="color"
            @keyup.enter="save"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn dark small @click="save" :color="color">
          <v-icon left small>mdi-content-save</v-icon>Сохранить
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn dark small @click="cancel" :color="color">
          <v-icon left small>mdi-close</v-icon>Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Диалоговое окно для смены пароля, содержит простую валидацию формы.
// Открывается из внешнего кода прямым вызовом метода .open() и обрабатывается
// как промис
import PasswordRules from '@/components/widgets/PasswordRules';

export default {
  name: 'change-password-dialog',
  components: { PasswordRules },
  props: {
    color: String
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    formIsValid: false,
    fieldRules: [v => !!v || 'Ввод в поле обязателен'],
    user: {
      username: '',
      password: '',
      newPassword: '',
      newPasswordRepeated: ''
    }
  }),
  methods: {
    open() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    save() {
      if (!this.formIsValid) {
        this.$notify.error('Форма заполнена некорректно');
        return;
      }
      this.$store
        .dispatch('changePassword', {
          username: this.user.username,
          password: this.user.password,
          newPassword: this.user.newPassword,
          newPasswordRepeated: this.user.newPasswordRepeated
        })
        .then(response => {
          this.$notify.success(response.data.message);
          this.resolve();
          this.dialog = false;
        })
        .catch(error => {
          this.$notify.error(error.response.data.message);
        });
    },
    cancel() {
      this.resolve();
      this.dialog = false;
    }
  }
};
</script>
