<template>
  <v-app>
    <v-container fill-height>
      <v-row justify="center" align-content="center">
        <v-col cols="4">
          <p class="text-center">
            <b>404</b>
            - Страница не найдена
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
// Страница 404 для не найденных маршрутов
export default {
  name: 'not-found'
};
</script>
