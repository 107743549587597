import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Notifications from 'vue-notification';

import { dateFilter, dateTimeFilter } from '@/filters/date';
import percentFilter from '@/filters/percent';
import roundFilter from '@/filters/round';

Vue.config.productionTip = false;

// Фильтры
Vue.filter('date', dateFilter);
Vue.filter('datetime', dateTimeFilter);
Vue.filter('percent', percentFilter);
Vue.filter('round', roundFilter);

// Плагины
Vue.use(Notifications);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');

// Шорткаты для нотификаций, по-другому не знаю как сделать, пакет никаких опций не предлагает
// Добавляя методы в прототип мы получим воможность их использования во всех компонетнах
Vue.prototype.$notify.success = function(text) {
  Vue.prototype.$notify({ type: 'success', title: 'Успешно', text: text });
};
Vue.prototype.$notify.warning = function(text) {
  Vue.prototype.$notify({ type: 'warning', title: 'Внимание', text: text });
};
Vue.prototype.$notify.error = function(text) {
  Vue.prototype.$notify({ type: 'error', title: 'Ошибка', text: text });
};
