<template>
  <v-dialog v-model="dialog" max-width="30%" @keydown.esc="cancel" scrollable persistent>
    <v-card>
      <v-card-title>Подтвердите действие</v-card-title>
      <v-card-text class="body-1" v-html="confirmationText"></v-card-text>
      <v-card-actions>
        <v-btn :color="getStatusColor(status)" small @click="confirm">
          <v-icon left small>{{ getStatusIcon(status) }}</v-icon>
          {{getStatusAction(status)}}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="warning" small @click="cancel">
          <v-icon left small>mdi-close</v-icon>Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Диалоговое окно для подтверждения отправки материалов, если выбраны различные статусы.
// Открывается из внешнего кода прямым вызовом метода .open() и обрабатывается
// как промис
import StatusUtils from '@/mixins/StatusUtils';

export default {
  name: 'confirmation-dialog',
  mixins: [StatusUtils],
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    status: null,
    count: null,
    validCount: null
  }),
  computed: {
    confirmationText() {
      // Если подобные диалоги потребуются где-то еще, то можно сделать универсальный компонент,
      // в который текст подтверждения будет подаваться из-вне (через .open())
      return `Вы выбрали <strong>${this.count}</strong> материала(ов),
              часть из них ранее направлены на доработку или согласованы.
              Согласовать/Отправить на доработку оставшиеся <strong>
              ${this.validCount}</strong> материалов?</div>`;
    }
  },
  methods: {
    open(status, count, validCount) {
      this.dialog = true;
      this.status = status;
      this.count = count;
      this.validCount = validCount;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>
