<template>
  <v-app id="app">
    <Navbar />
    <keep-alive exclude="password-reset-form">
      <router-view :key="$route.path" />
    </keep-alive>
  </v-app>
</template>

<script>
// Основной лэйаут с навигацией
import Navbar from '@/components/layout/Navbar';

export default {
  name: 'main-layout',
  components: { Navbar }
};
</script>
