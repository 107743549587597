<template>
  <v-main :class="bodyColor">
    <v-container fluid class="d-flex flex-column align-center justify-center">
      <img class="my-5" style="width:15%;" src="/static/img/home_logo.png" alt />
      <v-card class="mt-5" width="500">
        <v-card-title class="grey--text text--darken-2">Вход в систему</v-card-title>
        <v-card-text>
          <v-form v-model="formIsValid">
            <v-text-field
              v-model="user.username"
              light
              required
              :dense="denseInputs"
              :rules="fieldRules"
              :color="mainColor"
              prepend-icon="mdi-account"
              label="Имя пользователя"
              type="text"
              autocomplete="username"
              @keyup.enter="login"
            ></v-text-field>
            <v-text-field
              v-model="user.password"
              light
              required
              :dense="denseInputs"
              :rules="fieldRules"
              :color="mainColor"
              prepend-icon="mdi-lock"
              label="Пароль"
              type="password"
              autocomplete="current-password"
              @keyup.enter="login"
            ></v-text-field>
            <v-btn @click="login" block dark :color="mainColor">Войти</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
      <v-row dense class="text-center my-0">
        <a
          class="mr-5 grey--text text--darken-2 font-weight-light"
          @click="changePassword"
        >сменить пароль</a>
        <a
          class="grey--text text--darken-2 font-weight-light"
          @click="recoverPassword"
        >восстановить доступ</a>
      </v-row>
    </v-container>
    <v-footer absolute :color="bodyColor">
      <v-col class="text-center font-weight-light">
        <v-icon left small class="mb-1">mdi-copyright</v-icon>ООО Информ-Консалтинг
      </v-col>
      <v-col class="text-center font-weight-light">+7 (342) 237-14-77</v-col>
      <v-col class="text-center font-weight-light">
        <a href="mailto:support@incon.ru" style="color: inherit;">support@incon.ru</a>
      </v-col>
      <v-col class="text-center font-weight-light">
        <a target="_blank" href="https://www.incon.ru" style="color: inherit;">www.incon.ru</a>
      </v-col>
    </v-footer>

    <ChangePasswordDialog :color="mainColor" ref="changePassword" />
    <RecoverPasswordDialog :color="mainColor" ref="recoverPassword" />
  </v-main>
</template>

<script>
// Стартовый экран логина
import ChangePasswordDialog from '@/components/dialog/ChangePasswordDialog';
import RecoverPasswordDialog from '@/components/dialog/RecoverPasswordDialog';

export default {
  name: 'login',
  components: { ChangePasswordDialog, RecoverPasswordDialog },
  data: () => ({
    formIsValid: false,
    fieldRules: [v => !!v || 'Ввод в поле обязателен'],
    user: {
      username: '',
      password: ''
    },
    bodyColor: 'blue lighten-5',
    mainColor: 'deep-orange lighten-1'
  }),
  computed: {
    denseInputs() {
      return this.$vuetify.breakpoint.height < 800;
    },
    changePasswordDialog() {
      return this.$refs.changePassword;
    },
    recoverPasswordDialog() {
      return this.$refs.recoverPassword;
    }
  },
  methods: {
    login() {
      if (!this.formIsValid) {
        this.$notify.error('Форма заполнена некорректно');
        return;
      }
      this.$store
        .dispatch('login', {
          username: this.user.username,
          password: this.user.password
        })
        .then(response => {
          this.$notify.success(response.data.message);
        })
        .catch(error => {
          this.$notify.error(error.response.data.message);
        });
    },
    changePassword() {
      this.changePasswordDialog.open();
    },
    recoverPassword() {
      this.recoverPasswordDialog.open();
    }
  }
};
</script>

<style scoped lang="scss">
a:hover {
  text-decoration: underline;
}
</style>
