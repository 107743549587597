<template>
  <div>
    <v-navigation-drawer
      ref="drawer"
      app
      clipped
      stateless
      :width="navigation.width"
      v-model="navigation.shown"
    >
      <v-container fluid fill-height>
        <Tree />
      </v-container>
      <v-fab-transition>
        <v-btn
          fab
          dark
          small
          fixed
          bottom
          right
          title="Показать/Скрыть классификацию"
          color="deep-orange lighten-1"
          v-show="navigation.shown"
          @click="navigation.shown = !navigation.shown"
        >
          <v-icon>mdi-file-tree</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-navigation-drawer>
    <v-fab-transition>
      <v-btn
        fab
        dark
        small
        fixed
        bottom
        left
        title="Показать/Скрыть классификацию"
        color="deep-orange lighten-1"
        v-show="!navigation.shown"
        @click="navigation.shown = !navigation.shown"
      >
        <v-icon>mdi-file-tree</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
// Боковая панель, используем v-navigation-drawer
// Основное предназначение - хранение дерева и его управляющих компонентов.
// Здесь только логика по сокрытию и ресайзу самой панели
import Tree from '@/components/main/Tree';

export default {
  name: 'sidebar',
  components: { Tree },
  data: () => ({
    navigation: {
      shown: true,
      width: 550,
      borderSize: '4'
    }
  }),
  methods: {
    getWidth() {
      // Преобразуем px в число, чтобы Vuetify App корректно рассчитал размеры
      return parseInt(localStorage.getItem('sidebar-width'), 10);
    },
    setBorderWidth() {
      // Установка толщины draggable границы
      let i = this.$refs.drawer.$el.querySelector(
        '.v-navigation-drawer__border'
      );
      i.style.width = this.navigation.borderSize + 'px';
      i.style.cursor = 'ew-resize';
    },
    setResizeEvents() {
      // Установка/снятие прослушки событий мыши и установка изменения ширины
      const minSize = this.navigation.borderSize;
      const el = this.$refs.drawer.$el;
      const drawerBorder = el.querySelector('.v-navigation-drawer__border');
      const direction = el.classList.contains('v-navigation-drawer--right')
        ? 'right'
        : 'left';

      const resize = e => {
        document.body.style.cursor = 'ew-resize';
        let f =
          direction === 'right'
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        el.style.width = Math.max(minSize, f) + 'px';
      };

      drawerBorder.addEventListener(
        'mousedown',
        e => {
          e.preventDefault(); // отключаем выделение текста
          if (e.offsetX < minSize) {
            el.style.transition = 'initial';
            document.addEventListener('mousemove', resize, false);
          }
        },
        false
      );

      document.addEventListener(
        'mouseup',
        () => {
          el.style.transition = '';
          let currentWidth = el.style.width;
          this.navigation.width = currentWidth;
          // сохраним также в localStorage
          localStorage.setItem('sidebar-width', currentWidth);

          document.body.style.cursor = '';
          document.removeEventListener('mousemove', resize, false);
        },
        false
      );
    }
  },
  mounted() {
    // Загрузочная ширина сайд-бара
    // Пробуем получить из localStorage, если нет - считаем
    let width = this.getWidth();
    if (!width) {
      width = {
        xs: '250',
        sm: '400',
        md: '400',
        lg: '400',
        xl: '600'
      }[this.$vuetify.breakpoint.name];
    }
    this.navigation.width = width ? width : 550;
    // Наблюдение за ресайзом
    this.setBorderWidth();
    this.setResizeEvents();
  }
};
</script>
