<template>
  <v-dialog v-model="dialog" max-width="25%" @keydown.esc="cancel" scrollable persistent>
    <v-card>
      <v-card-title>
        Восстановление пароля
        <v-spacer></v-spacer>
        <v-icon @click="cancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text
        class="body-1"
      >Забыли пароль? Введите свой адрес электронной почты ниже, и мы вышлем вам инструкцию, как установить новый пароль.</v-card-text>
      <v-card-text>
        <v-form ref="form" :lazy-validation="true">
          <v-text-field
            v-model="email"
            light
            required
            :rules="fieldRules"
            prepend-icon="mdi-email"
            label="Введите e-mail"
            type="email"
            :color="color"
            @keyup.enter="recover"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn dark small @click="recover" :color="color">
          <v-icon left small>mdi-content-save</v-icon>Восстановить пароль
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn dark small @click="cancel" :color="color">
          <v-icon left small>mdi-close</v-icon>Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Диалоговое окно для отправки почты.
// Открывается из внешнего кода прямым вызовом метода .open() и обрабатывается
// как промис
export default {
  name: 'recover-password-dialog',
  props: {
    color: String
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    fieldRules: [v => !!v || 'Ввод в поле обязателен'],
    email: ''
  }),
  methods: {
    open() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    recover() {
      this.fieldRules.push(
        v =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'Пожалуйста, проверьте электронную почту'
      );
      let isValid = this.$refs.form.validate();

      if (!isValid) {
        this.$notify.error('Форма заполнена некорректно');
        return;
      }
      this.$store
        .dispatch('sendRecoveryEmail', { email: this.email })
        .then(response => {
          this.$notify({
            type: 'success',
            title: 'Письмо отправлено',
            text: response.data.message,
            duration: 10000
          });
          this.resolve();
          this.dialog = false;
        })
        .catch(error => {
          this.$notify.error('Произошла ошибка');
        });
    },
    cancel() {
      this.resolve();
      this.dialog = false;
    }
  }
};
</script>
