<template>
  <v-menu
    :value="showMenu"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
    :transition="false"
  >
    <v-list dense class="pa-1">
      <!-- input-value=true чтобы применился цвет -->
      <v-list-item color="primary" :input-value="true">
        <v-list-item-title v-if="contextNode">
          <b>Класс</b>
          : {{ contextNode.data.code }}
        </v-list-item-title>
      </v-list-item>
      <v-divider />
      <template v-for="(item, index) in items">
        <v-divider v-if="item.title == 'divider'" :key="index"></v-divider>
        <v-list-item
          v-else
          :key="index"
          class="pa-0"
          @click="$emit('contextClick', item.event)"
        >
          <v-list-item-icon class="mx-1">
            <v-icon size="18" left>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
// Контекстное меню для дерева, используется стандарнтый компонент v-menu из vuetify,
// Для каждого пункта меню опрелено событие, которые поднимается наверх, и обрабатывается
// непосредственно в дереве

export default {
  name: 'tree-context-menu',
  props: {
    contextNode: Object
  },
  data: () => ({
    showMenu: false,
    x: 0,
    y: 0,
    items: [
      {
        icon: 'mdi-chevron-double-right',
        title: 'Развернуть все',
        event: 'expand-all'
      },
      {
        icon: 'mdi-chevron-double-left',
        title: 'Свернуть все',
        event: 'collapse-all'
      },
      { title: 'divider' },
      {
        icon: 'mdi-chevron-double-down',
        title: 'Развернуть класс',
        event: 'expand-class'
      },
      {
        icon: 'mdi-chevron-double-up',
        title: 'Свернуть класс',
        event: 'collapse-class'
      },
      { title: 'divider' },
      {
        icon: 'mdi-card-bulleted-outline',
        title: 'Информация о классе',
        event: 'class-info'
      }
    ]
  }),
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      // Небольшой таймаут, чтобы окошко успевало исчезнуть
      setTimeout(() => {
        this.x = e.clientX;
        this.y = e.clientY;
        this.$nextTick(() => {
          this.showMenu = true;
        });
      }, 50);
    }
  }
};
</script>

<style scoped lang="scss">
// Сделаем меню компактным
.v-list--dense .v-list-item .v-list-item__icon {
  margin-top: 0;
}

.v-list--dense .v-list-item {
  height: 28px;
  min-height: 28px;
}
</style>
