<template>
  <v-card height="100%" min-width="25vw" width="100%" class="d-flex flex-column">
    <v-card-title class="subtitle-1 font-weight-bold py-1">
      Система классов для согласования
      <v-icon right @click="setTaxonomy" title="Обновить дерево">mdi-refresh</v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pt-1">
      <v-row>
        <v-col md="6" xl="7" align-self="center" class="pt-0 pb-1">
          <v-select
            :items="treeVariants"
            item-text="name"
            item-value="id"
            dense
            solo
            hide-details
            class="small-input"
            v-model="selectedVariant"
          ></v-select>
        </v-col>
        <v-col md="6" xl="5" align-self="center" class="pt-0 pb-1">
          <v-switch
            dense
            label="Классы в работе"
            color="success"
            class="small-input"
            v-model="showClassesInProgress"
            @change="setTaxonomy"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row dense class="my-0">
        <v-col md="8" xl="9" class="py-0">
          <v-text-field
            solo
            clearable
            dense
            hide-details
            v-model="objSearch"
            label="Поиск класса по наименованию материалов"
            prepend-inner-icon="mdi-database-search"
            class="small-input"
            @keyup.enter="performSearch"
          ></v-text-field>
        </v-col>
        <v-col md="4" xl="3" class="py-0">
          <v-btn block small @click="performSearch">
            <v-icon small left>mdi-search-web</v-icon>Поиск
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col md="8" xl="9" class="py-0">
          <v-text-field
            solo
            clearable
            dense
            hide-details
            v-model="classSearch"
            label="Поиск по наименованию/коду класса"
            prepend-inner-icon="mdi-database-search"
            @keyup.enter="performSearch"
            class="small-input"
          ></v-text-field>
        </v-col>
        <v-col md="4" xl="3" class="py-0">
          <v-btn block small @click="resetSearch">
            <v-icon small left>mdi-close</v-icon>Сбросить
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-skeleton-loader
      v-if="loading"
      class="mx-2"
      type="list-item-three-line, divider, list-item-two-line, divider, list-item-three-line"
    ></v-skeleton-loader>
    <LiquorTree
      v-show="!loading"
      :ref="treeRef"
      :options="options"
      @node:dblclick="selectClassObjects"
      @node:expanded="updateState"
      @node:collapsed="updateState"
      @node:selected="handleSelectNode"
      class="flex-grow-1"
    >
      <!--
        Определяется контент узла иерархии.
        0. Картинка папки для класса (см. метод getNodeVisuals)
        1. Нерелевантные узлы красим серым
        2. Устанавливаем нотификации по объектам/классам (если есть)
        3. Устанавливаем иконку, если класс находится на доработке (class_is_busy)
        4. Устанавливаем все метрики (obj_count_in_work, obj_count_rejected)
      -->

      <span
        slot-scope="{ node }"
        :title="getNodeTitle(node)"
        :class="!node.data.user_relevant ? 'grey--text': ''"
        @contextmenu="triggerMenu"
      >
        <span>
          <v-icon size="22" v-text="getNodeVisuals(node).icon" :color="getNodeVisuals(node).color"></v-icon>
          <v-icon
            right
            size="18"
            v-if="node.data.class_is_busy"
            v-text="getStatusIcon(node.data.stat_id)"
            :color="getStatusColor(node.data.stat_id)"
            :title="getStatusTitle(node.data.stat_id)"
          ></v-icon>
          <v-icon
            right
            size="18"
            v-if="node.data.cls_notification"
            v-text="getStatusIcon(10)"
            :color="getStatusColor(10)"
            :title="getStatusTitle(10)"
          ></v-icon>
          <v-chip
            right
            label
            color="info"
            v-if="node.data.obj_notifications"
            v-text="node.data.obj_notifications"
            :title="`Добавлены материалы для повторного согласования: ${node.data.obj_notifications}`"
            style="height: 16px"
            class="mx-2"
          ></v-chip>
        </span>
        <span class="ml-2 mr-1 font-weight-bold">{{ node.data.code }}</span>
        <span>{{ node.data.name }}</span>
        <span v-if="node.data.is_leaf_node">
          <span
            class="mx-2 font-weight-bold"
            v-if="(node.data.obj_count_in_work === 0) && (node.data.obj_count_rejected > 0)"
          >{{ node.data.obj_count_in_work }}</span>
        </span>
        <span class="mx-2 font-weight-bold error--text" v-if="node.data.is_leaf_node">
          <span v-if="(node.data.obj_count_in_work > 0)">{{ node.data.obj_count_in_work }}</span>
        </span>
      </span>
    </LiquorTree>
    <TreeContextMenu
      ref="treeContext"
      :contextNode="contextNode"
      @contextClick="handleContextClick"
    />
  </v-card>
</template>

<script>
// Непосредственно дерево классов, вся логика его
// отображения и загрузки, а также управляющие элементы.
// Весь выбор классов сделан через сервер и синхронизирован,
// то есть при каждом обновлени учитываются все, уже установленные
// фильтры, режимы, и т.д. Таким образом обеспечивается консистентность.
// ВАЖНО: v-model на дереве не используется, выбранным узлом управляем программно,
// так как в дереве нет простой опции на выбор узла двойным щелчком.

// Встроенные фильтры на уровне JavaScript НЕ ИСПОЛЬЗУЮТСЯ
import StatusUtils from '@/mixins/StatusUtils';
import LiquorTree from 'liquor-tree';
import TreeContextMenu from '@/components/context/TreeContextMenu';

export default {
  name: 'tree',
  mixins: [StatusUtils],
  components: { LiquorTree, TreeContextMenu },
  data() {
    return {
      treeRef: 'tree',
      loading: false,
      options: {
        multiple: false,
        parentSelect: true,
        propertyNames: {
          id: 'cls_id' // мэпим cls_id в id
        }
      },
      minSearchStringLength: 3,
      objSearch: '',
      classSearch: '',
      showClassesInProgress: false,
      showMenu: false,
      contextNode: null // для хранение узла, на котором открывается контекстное меню
    };
  },
  computed: {
    treeVariants() {
      // Варианты отображения дерева это часть конфигурации, они лежат в хранилище
      // с момента монтирования основного приложения после логина
      return this.$store.state.config.tree.treeVariants;
    },
    selectedVariant: {
      // Выбранный вариант. Делаем двухстороннее связывание с хранилищем, так
      // как мы устанавливаем на него наблюдение
      get() {
        return this.$store.state.config.tree.selectedVariant;
      },
      set(value) {
        this.$store.commit('SET_SELECTED_VARIANT', value);
      }
    },
    validObjSearch() {
      // Условия валидности поиска по текстам объектов
      return this.objSearch &&
        this.objSearch.length >= this.minSearchStringLength
        ? this.objSearch
        : '';
    },
    validClassSearch() {
      // Условия валидности поиска по текстам классов
      return this.classSearch &&
        this.classSearch.length >= this.minSearchStringLength
        ? this.classSearch
        : '';
    }
  },
  methods: {
    setTaxonomy() {
      // Универсальный и основной метод по получению и установке
      // данных дерева. Должен учитывать все установленные режимы и поиски
      // Во всех других местах вызывается именно он. Фактически это единственное место,
      // где должен вызываться экшн fetchTreeModel
      let tree = this.$refs[this.treeRef];
      this.loading = true;
      const params = {
        variant: this.selectedVariant,
        objSearch: this.objSearch,
        classSearch: this.classSearch,
        showClassesInProgress: this.showClassesInProgress
      };
      return this.$store.dispatch('fetchTreeModel', params).then(() => {
        tree.setModel(this.$store.state.tree.treeData);
        this.loading = false;
        // после установки дерева применим сохраненное состояние
        this.setState();
      });
    },
    performSearch() {
      // Проверяем поля поиска и перезагружаем дерево
      if (this.validObjSearch.length > 0 || this.validClassSearch.length > 0) {
        this.setTaxonomy();
      } else {
        this.$notify.warning(
          `Для поиска необходимо ввести как минимум ${this.minSearchStringLength} символа`
        );
      }
    },
    resetSearch() {
      // Сбрасывем поиск и перезагружаем дерево
      this.objSearch = '';
      this.classSearch = '';
      this.setTaxonomy();
    },
    selectClassObjects(cls) {
      // Складываем в хранилише бизнес-данные выбранного класса,
      // предварительно удалив нотификации.
      // Сразу же подтягиваем объекты для выбранного класса
      cls.data.obj_notifications = 0;
      cls.data.cls_notification = false;
      this.$store.commit('SET_SELECTED_CLASS', cls.data);
      this.$store.dispatch('fetchObjects');
      this.$store.dispatch('getClassMeta', cls.data.cls_id);

      // Установим узел как выбранный и сохраним состояние
      this.selectNode(cls);
      this.updateState();
    },
    openClassCard(cls_id) {
      // Получение с сервера данных класса и открытие карточки
      this.$store
        .dispatch('getClassInfo', cls_id)
        .then(_ => this.$store.commit('CLASS_MODAL', true));
    },
    triggerMenu(event) {
      // Открытие контекстного меню
      this.contextNode = event.currentTarget.__vue__.node; // сохраняем класс, по которому кликнули правой кнопкой
      this.$refs.treeContext.show(event);
    },
    handleContextClick(event) {
      // Обработка событий контекстного меню
      switch (event) {
        case 'expand-all':
          this.expandAll();
          break;
        case 'collapse-all':
          this.collapseAll();
          break;
        case 'expand-class':
          this.expandAll(this.contextNode);
          break;
        case 'collapse-class':
          this.collapseAll(this.contextNode);
          break;
        case 'class-info':
          this.openClassCard(this.contextNode.id);
          break;
      }
    },
    getNodeTitle(node) {
      // Формирование арибута title для узлов дерева
      if (
        node.data.obj_count_in_work === undefined ||
        node.data.obj_count_rejected === undefined
      ) {
        return;
      }

      let title;
      if (node.data.obj_count_in_work < 0) title = 'Отсутствуют материалы';
      else if (
        node.data.obj_count_in_work === 0 &&
        node.data.obj_count_rejected === 0
      )
        title = 'Все материалы класса согласованы';
      else
        title = `Материалов для согласования: ${node.data.obj_count_in_work}. На доработке: ${node.data.obj_count_rejected}`;
      return title;
    },
    getNodeVisuals(node) {
      // Выбор картинки и цвета для узла
      let defaultColor = '';
      let defaultIcon = node.expanded()
        ? 'mdi-folder-open-outline'
        : 'mdi-folder-outline';
      let defaultVisuals = { color: defaultColor, icon: defaultIcon };

      // Если класс не релевантен - ничего не подкрашиваем
      if (!node.data.user_relevant) {
        return defaultVisuals;
      }
      // Если класс нижнего уровня - просто выбираем от атрибута complete
      if (!node.children.length) {
        return node.data.is_complete
          ? { color: 'success', icon: 'mdi-folder-star' }
          : defaultVisuals;
      }

      // Для родительских классов необходимо учитывать статус
      // потомков самого НИЖНЕГО уровня. Если есть хотя бы один
      // из них еще в работе - выходим
      let allClassesCompleted = true;
      node.recurseDown(n => {
        if (!n.children.length && !n.data.is_complete) {
          allClassesCompleted = false;
          return;
        }
      });

      // Если раньше не вышли - значит все согласовано
      return allClassesCompleted
        ? {
            color: 'success',
            icon: node.expanded() ? 'mdi-folder-open' : 'mdi-folder-star'
          }
        : defaultVisuals;
    },
    expandAll(node) {
      // Вспомогательный метод для рекурсивного открытия потомков узла node
      // Если node не задан - открываем все дерево
      // у дерева есть встроенный метод expandAll, но он поднимает событие открытия
      // для каждого узла дерева, чем убивает нашу стейт-машину (смотри ниже)
      if (node) {
        if (node.canExpand()) {
          node.states.expanded = true;
        }
        node.children.forEach(child => this.expandAll(child));
        return;
      }

      this.$refs[this.treeRef].findAll(true).forEach(node => {
        if (node.canExpand()) {
          // node.expand() - тоже не подходит, так как делает emit('expanded')
          node.states.expanded = true; // ОК
        }
      });
    },
    collapseAll(node) {
      // Аналогично для рекурсивного схлапывания узлов
      if (node) {
        if (node.canCollapse()) {
          node.states.expanded = false;
        }
        node.children.forEach(child => this.collapseAll(child));
        return;
      }

      this.$refs[this.treeRef].findAll(true).forEach(node => {
        if (node.canCollapse()) {
          node.states.expanded = false; // ОК
        }
      });
    },
    selectNode(node) {
      // Выбор узла делаем напрямую, так как
      // node.select() эмитит событие select, которое у нас под заглушкой.
      // Такой workaround обусловлен сыростью библиотеки liquor-tree,
      // при программном выборе узла нам не дают возможности управлять событием
      node.tree.unselectAll(); // В дереве есть специальный кэш выбранных узлов, чтобы не циклить по всем нодам
      node.states.selected = true;
      node.tree.select(node); // Таким образом узел попадает в кэш выбранных узлов
    },
    // Ниже идут методы для сохранения состояния дерева - открытых и выбранных(ого) узлов
    // Они обеспечивают персистентность состояния дерева при перезагрузки сохранения,
    // так как сохраняют данные в хранилище браузера.
    // На данный момент никаких оптимизаций не сделано, поэтому стейт целиком
    //  перезаписывается на каждое открытие/закрытие и выбор узла. Это происходит быстро (5-50мс),
    // поэтому я оставил так.
    loadState() {
      // Загрузка состояния из локального хранилища
      return JSON.parse(localStorage.getItem(this.treeRef));
    },
    updateState() {
      /*
      Актуализация состояния дерева
      ВАЖНО: нельзя использовать встроенные методы
      дерева expandAll и collapseAll, так как они
      вызывают события обновления на каждом узле.

      Разработчик LiquorTree не дает возможности
      отключать выброс события через опцию, поэтому
      реализовывать открытие и закрытие всех узлов
      необходимо итеративно, прямым изменением стейта:

          this.$refs[this.treeRef].findAll(node => {
            if (node.canExpand()) {
              // node.expand() - тоже не подходит, так как делает emit('expanded')
              node.states.expanded = true;  // ОК
            }
          });

      Aналогично с collapsed и selected.

      Для удобства в компоненте созданы соответствующие методы,
      которые нужно использовать (this.expandAll, this.collapseAll)
      */
      let tree = this.$refs[this.treeRef];
      let newState;

      if (this.selectedVariant != 'opt_relevant_flat') {
        // Мы отключаем перезапись стейта для плоского вывода,
        // вместо этого, если мы работаем в режиме плоского дерева,
        // мы просто читаем последний стейт из localStorage
        newState = {
          expanded: [],
          selected: []
        };
      } else {
        newState = this.loadState();
      }
      // Сбор нового состояния
      tree.findAll(true).forEach(node => {
        // Если работаем в плоской иерархии,
        // то не пишем состояния открытых узлов,
        // так как они все считаются схлопнутыми
        if (node.states.expanded) {
          newState.expanded.push(node.id);
        }

        if (node.states.selected) {
          newState.selected.push(node.id);
        }
      });
      // Запись нового состояния
      localStorage.setItem(this.treeRef, JSON.stringify(newState));
    },
    resetState() {
      // Вспомогательный метод обнуления состояния, где-то может пригодиться
      let newState = {
        expanded: [],
        selected: []
      };
      localStorage.setItem(this.treeRef, JSON.stringify(newState));
    },
    setState() {
      // Считывание состояния из localStorage и применение его к дереву
      let tree = this.$refs[this.treeRef];
      let state = this.loadState();

      if (!state) {
        return;
      }
      // Открываем узлы, которые были открыты, выбираем те,
      // что были выбраны (один, в нашем случае)
      state.expanded.forEach(nodeId => {
        let node = tree.find({ id: nodeId.toString() });
        if (node) {
          node.expand();
        }
      });

      state.selected.forEach(nodeId => {
        let selection = tree.find({ id: nodeId.toString() });
        if (selection) {
          selection.forEach(n => this.selectNode(n));
        }
      });
    },
    handleSelectNode(node) {
      // Нам не нужно, чтобы узел выделялся при одинарном клике (событие select),
      // поэтому поставим заглушку здесь. Из-за заглушки нельзя использовать встроенные
      // метод select у узла дерева. Для корректного выделения узла нужно использовать метод
      // selectNode, созданный для этой цели
      node.unselect();
    }
  },
  watch: {
    selectedVariant() {
      // При изменении варианта - перезагружаем дерево
      this.setTaxonomy();
    },
    '$store.state.tree.classesToUpdate': {
      handler(newClassesInfo) {
        // Если появляются данные для обновления метаданных классов - обновляем
        if (newClassesInfo.length === 0) {
          return;
        }

        let tree = this.$refs[this.treeRef];

        newClassesInfo.forEach(class_ => {
          let node = tree.find({ data: { cls_id: class_.cls_id } })[0];
          node.setData(class_.meta);
        });
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
// Фикс кнопок внутри текстовых полей. Возможно баг Vuetify или
// mdi icons
.v-text-field.v-input--dense .v-icon {
  margin-top: 0 !important;
}

// Стили дерева
.tree {
  font-size: 13px;
  overflow-y: auto;
}

ul.tree-children {
  padding-left: 18px;
}

.tree-anchor {
  padding: 0 !important;
  margin: 0 !important;
}

.tree-content {
  padding: 0 !important;
}

.tree-arrow {
  height: 28px !important;
}

.tree-arrow.has-child:after {
  border: 2px solid #686868 !important;
  border-left: 0 !important;
  border-top: 0 !important;
  top: 50% !important;
  height: 6px !important;
  width: 6px !important;
}

// цвета
.tree-node.selected > .tree-content {
  background-color: $info-background-color !important;
  border-radius: 1rem;
}

.tree-node.selected > .tree-content > .tree-anchor {
  color: $info-color !important;
}

.tree-content:hover {
  border-radius: 1rem;
}

.tree-filter-empty {
  font-size: 13px;
  padding: 10px !important;
}
// Анимация открытия узла
.tree-children {
  transition-duration: 0.1s !important;
}
</style>
