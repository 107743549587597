<template>
  <div id="app">
    <component :is="layout"></component>
    <notifications classes="flashpool" width="500px" :duration="4000" position="bottom right" />
    <v-snackbar v-model="showIEAlert" :timeout="10000">
      Internet Explorer не поддерживается.
      <v-btn color="pink" text @click="showIEAlert = false">Закрыть</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
// Основной шаблон, тут только выбираем layout и подгребаем общие для всего приложения
// вещи - уведомления, стили
import MainLayout from '@/layouts/MainLayout';
import EmptyLayout from '@/layouts/EmptyLayout';

export default {
  name: 'app',
  components: { MainLayout, EmptyLayout },
  data: () => ({
    showIEAlert: false
  }),
  computed: {
    layout() {
      return this.$route.meta.layout;
    }
  },
  mounted() {
    // Дадим наставление если пользователь зашел с IE
    let ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1) {
      this.showIEAlert = true;
    }

    // Отменим выделение текста с шифтом
    document.addEventListener('keydown', function(e) {
      let keyPressed = e.keyCode;
      if (keyPressed == 16) {
        let html = document.querySelector('html');
        html.classList.add('unselectable');

        document.addEventListener('keyup', function() {
          let html = document.querySelector('html');
          html.classList.remove('unselectable');
        });
      }
    });
  }
};
</script>

<style lang="scss">
// Базовые настройки CSS для всего приложения

// Убираем скролл на полноэкранном режиме - особенность Vuetify
html {
  overflow-y: auto !important;
}
// Подсказки на неактивных кнопках все-таки покажем
.v-btn--disabled {
  cursor: default;
  pointer-events: auto;
}
// Утилита для блокировки выделения текста
.unselectable {
  -moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
// Скроллбары
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background: #ffffff00;
}
::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #b9bec4;
}
:root {
  scrollbar-color: #cecece #ffffff00 !important;
  scrollbar-width: thin !important;
}
// Дополнения vuetify
// Уменьшим инпуты (dense инпуты vuetify слишком большие)
.small-input .v-input__control {
  min-height: 28px !important;
  * {
    font-size: 14px;
  }
}

// Стили уведомлений
@import '@/assets/scss/flashpool.scss';
</style>
