import axios from 'axios';
import router from '@/router';
import store from '@/store';

// Сконфигурируем axios для использования по всему приложению
const api = axios.create();

api.defaults.xsrfCookieName = 'csrftoken';
api.defaults.xsrfHeaderName = 'X-CSRFToken';

// Установка глобального индикатора загрузки
api.interceptors.request.use(config => {
  store.commit('SET_LOADING_STATUS', true);
  return config;
});

// При получении 401 (Пользователь не авторизован на сервере)
// снимаем также его авторизациюна фронтэнде, иначе фронт может зависнуть,
// например, когда истекла сессия
api.interceptors.response.use(
  response => {
    store.commit('SET_LOADING_STATUS', false);
    return response;
  },
  error => {
    store.commit('SET_LOADING_STATUS', false);
    if (error.response.status === 401) {
      localStorage.setItem('isAuthenticated', 'false');
      store.commit('SET_IS_AUTHENTICATED', 'false');
      store.commit('RESET');
      router.push({ name: 'logout' });
    }
    return Promise.reject(error);
  }
);

export default api;
