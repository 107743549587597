import Tabulator from 'tabulator-tables';
import {
  checkStatuses,
  getStatusColor,
  getStatusIcon,
  getStatusTitle,
  getStatusAction,
  statusesInWork,
  statusesRejected
} from '@/helpers/statuses';

// ID DOM-элемента для монтирования таблицы
export const TABLE_ID = 'content-table';

const decodeStatuses = function(cell, formatterParams, onRendered) {
  // Функция для рендеринга столбца статусов, преобразует статус в иконку
  let status = cell.getValue();

  let title = getStatusTitle(status);
  let color = getStatusColor(status);
  let icon = getStatusIcon(status);

  let result = `<i title="${title}" class="${color}--text v-icon mdi ${icon}"></i>`;
  return result;
};

const disableDoubleClickSelection = function() {
  // Устанавливает запрет на выделение текста перетаскиванием мыши
  document.querySelectorAll('.tabulator-cell').forEach(el => {
    el.addEventListener('mousedown', function(e) {
      if (e.detail > 1) {
        e.preventDefault();
      }
    });
  });
};

// Локализация таблицы
const ruLocale = {
  ajax: {
    loading: 'Загрузка',
    error: 'Ошибка'
  },
  pagination: {
    page_size: 'Записей на странице',
    page_title: 'Страница',
    first: '<<',
    first_title: 'Первая страница',
    last: '>>',
    last_title: 'Последняя страница',
    prev: '<',
    prev_title: 'Предыдущая страница',
    next: '>',
    next_title: 'Следующая страница',
    all: 'Все'
  },
  headerFilters: {
    default: 'Введите текст...',
    columns: {
      hist_full_name: 'Введите текст...'
    }
  }
};

// Контекстное меню заголовков
const headerMenu = function() {
  let menu = [];
  // Колонки, к скрытию/открытию которых мы не даем доступ
  let blacklist = [
    'obj_id',
    'nontarget',
    'duplicated',
    'stat_id',
    'hist_short_name'
  ];

  let columns = this.getColumns();

  for (let column of columns) {
    let field = column.getDefinition().field;

    if (blacklist.indexOf(field) >= 0) {
      continue;
    }

    // Картинки чек-боксов
    let icon = document.createElement('i');
    icon.classList.add('mdi');
    icon.classList.add('v-icon');
    icon.classList.add('v-icon--left');

    if (column.isVisible()) {
      icon.classList.add('mdi-checkbox-marked-outline');
      icon.classList.add('checked');
    } else {
      icon.classList.add('mdi-checkbox-blank-outline');
    }

    // Лэйблы
    let label = document.createElement('span');
    let title = document.createElement('span');

    title.textContent = ' ' + column.getDefinition().title;

    label.appendChild(icon);
    label.appendChild(title);

    // Создание меню
    menu.push({
      label: label,
      action: function(e) {
        // Предотвращение закрытия меню
        e.stopPropagation();

        // Меняем видимость на противоположную
        column.toggle();

        // Соответствующая смена картинки
        if (column.isVisible()) {
          icon.classList.remove('mdi-checkbox-blank-outline');
          icon.classList.add('mdi-checkbox-marked-outline');
          icon.classList.add('checked');
        } else {
          icon.classList.remove('mdi-checkbox-marked-outline');
          icon.classList.remove('checked');
          icon.classList.add('mdi-checkbox-blank-outline');
        }
      }
    });
  }

  return menu;
};

// Настройки для внешнего управления таблицей
export const selectionOptions = [
  { code: 'all', name: 'Выделить все' },
  { code: 'page', name: 'Выделить страницу' }
  // Опция "снять выделение" временно убрана. Есть мнение что она не нужна
  // { code: 'drop', name: 'Снять выделение' }
];

export const switchFilters = [
  {
    name: 'inprogress_rejected',
    label: 'Материалы на доработке',
    value: false,
    hideFromClient: true,
    filter: { field: 'stat_id', type: 'in', value: statusesRejected }
  },
  {
    name: 'inprogress',
    label: 'Материалы в работе',
    value: false,
    hideFromClient: false,
    filter: { field: 'stat_id', type: 'in', value: statusesInWork }
  },
  {
    name: 'etalons',
    value: false,
    label: 'Целевые материалы',
    hideFromClient: false,
    filter: { field: 'nontarget', type: '=', value: 0 }
  },
  {
    name: 'duplicates',
    value: false,
    label: 'Дубли',
    hideFromClient: false,
    filter: { field: 'duplicated', type: '=', value: 1 }
  }
];

// Описание колонок. Даный объект должен соответствовать данным, приходящим с сервера,
// возможно, стоит определение колонок вынести в настройки приложения и переавать с сервера,
// после чего здесь добавить не сериазилуемые данные (форматтеры, менюшки)
const tabulatorColumns = [
  {
    title: 'Статус',
    field: 'stat_id',
    visible: true,
    frozen: true,
    hozAlign: 'center',
    formatter: decodeStatuses,
    headerMenu: headerMenu
  },
  { title: 'ID материала', field: 'obj_id', visible: false },
  { title: 'Код', field: 'hist_id', visible: true, headerFilter: true },
  {
    title: 'Исходное наименование',
    field: 'hist_short_name',
    visible: true,
    headerFilter: true
  },
  {
    title: 'Исходное полное наименование',
    field: 'hist_full_name',
    visible: true,
    headerFilter: true
  },
  {
    title: 'Исходная ЕИ',
    field: 'hist_uom',
    visible: true,
    headerFilter: true
  },
  {
    title: 'Нормализованное наименование',
    field: 'norm_short_name',
    visible: true,
    headerFilter: true
  },
  {
    title: 'Нормализованное полное наименование',
    field: 'norm_full_name',
    visible: true,
    headerFilter: true
  },
  {
    title: 'Нормализованная ЕИ',
    field: 'norm_uom',
    visible: true,
    headerFilter: true
  },
  {
    title: 'Последний комментарий',
    field: 'objectcomment__com_text',
    visible: true,
    headerFilter: true
  },
  { title: 'Нецелевая запись', field: 'nontarget', visible: false },
  { title: 'Дубль', field: 'duplicated', visible: false }
];

// Оборачиваем создание табулятора в функцию, так как мы хотим передать туда Vue компонент
// таким образом мы связываем некоторые данные табулятора и можем использовать их во VUE
// Это выглядит как хак, но так гораздо удобнее, чем иметь глобальную таблицу,
// которая общается со Vue также через глобальные переменные
export const getTabulator = function(vue) {
  let tabulator = new Tabulator(vue.$refs.objects, {
    data: vue.tableData,
    height: '100%',
    pagination: 'local',
    paginationSize: 20,
    paginationSizeSelector: [10, 20, 50, 100, true],
    movableColumns: true,
    placeholder: 'Отсутствуют записи',
    persistence: {
      columns: true,
      sort: true,
      page: {
        size: true,
        page: false
      }
    },
    selectable: true,
    selectableRangeMode: 'click',
    tooltips: function(cell) {
      let fname = cell.getValue();
      if (fname.length > 60) {
        return fname;
      }
    },
    columns: tabulatorColumns,
    locale: true,
    langs: { ru: ruLocale },
    footerElement: `<span id="${vue.customFooterId}"></span>`,
    tableBuilt: function() {
      // Установка прослушки ctrl+a на выделение всей таблицы
      // Функция НЕ анонимная, так как в this нам нужна сама таблица, а не инстанс Vue
      let tabulator = this;
      document
        .getElementById(TABLE_ID)
        .addEventListener('keydown', function(e) {
          if (e.ctrlKey && (e.keyCode == 65 || e.keyCode == 97)) {
            e.preventDefault();
            tabulator.selectRow('active');
          }
        });
    },
    dataLoaded: data => {
      // Отдаем по vue количество данных
      vue.tableStats.count = data.length;
      disableDoubleClickSelection();
    },
    pageLoaded: pageno => {
      // Убирать выделение текста необходимо при каждой загрузке страницы
      disableDoubleClickSelection();
    },
    cellDblClick: function(e, cell) {
      // Отключает выделение всего текста ячейки дабл-кликом (в нашем случае не требуется)
      e.preventDefault();
    },
    rowDblClick: (e, row) => {
      // Открываем карточку с объектом по дабл-клику на строку таблицы
      e.preventDefault();
      e.stopPropagation();
      let obj_id = row.getData().obj_id;
      vue.$store
        .dispatch('getObjectInfo', obj_id)
        .then(_ => (vue.showObjectCard = true));
    },
    dataFiltered: (filters, rows) => {
      // Обновление tableStats при изменении фильтров
      // Фильтры заголовка необходимо отдельно поискать, почему-то их не передают
      // в этот колбэк. На this.getHeaderFilters при инициализации также будет ругаться
      let headerFilterSet = false;
      document
        .querySelectorAll(`#${TABLE_ID} .tabulator-header-filter input`)
        .forEach(i => {
          if (i.value) {
            headerFilterSet = true;
          }
        });

      if (filters.length || headerFilterSet) {
        vue.tableStats.isFiltered = true;
        vue.tableStats.filtered = rows.length;
      } else {
        vue.tableStats.isFiltered = false;
        vue.tableStats.filtered = 0;
      }
    },
    rowSelectionChanged: (data, rows) => {
      // Обновление tableStats при выделении
      // А также передача в компонент Vue самого выделения (очень удобно),
      // его валидной части, и количества (для управления подтверждениями)
      let nontargets = 0;
      vue.tableStats.selected.all = data.length;

      if (vue.tableStats.selected.all > 0) {
        data.forEach(row => {
          if (row.nontarget === 1) {
            nontargets += 1;
          }
        });
        vue.tableStats.selected.nontargets = nontargets;
      } else {
        vue.tableStats.selected.all = 0;
      }

      vue.tableSelection.rows = rows;
      vue.tableSelection.count = rows.length;
      vue.tableSelection.validCount = checkStatuses(rows);
    }
  });
  return tabulator;
};
