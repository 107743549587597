<template>
  <v-dialog :value="true" max-width="25%" scrollable persistent>
    <v-card>
      <v-card-title>Введите новый пароль</v-card-title>
      <v-card-text>
        <PasswordRules />
      </v-card-text>
      <v-card-text
        class="body-1"
      >Пожалуйста, введите новый пароль дважды, чтобы мы могли убедиться в корректности написания.</v-card-text>
      <v-card-text>
        <v-form v-model="formIsValid">
          <v-text-field
            v-model="user.newPassword"
            light
            required
            :color="color"
            :rules="fieldRules"
            prepend-icon="mdi-form-textbox-password"
            label="Новый пароль"
            type="password"
            autocomplete="current-password"
            @keyup.enter="save"
          ></v-text-field>
          <v-text-field
            v-model="user.newPasswordRepeated"
            light
            required
            :color="color"
            :rules="fieldRules"
            prepend-icon="mdi-form-textbox-password"
            label="Подтвердите новый пароль"
            type="password"
            autocomplete="current-password"
            @keyup.enter="save"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn dark small @click="save" :color="color">
          <v-icon left small>mdi-content-save</v-icon>Сохранить
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn dark small @click="cancel" :color="color">
          <v-icon left small>mdi-close</v-icon>Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PasswordRules from '@/components/widgets/PasswordRules';

// Смена пароля по секретной ссылке, токен и закодированный ID пользователя
// передаются как свойства маршрута, после чего меняются.
export default {
  name: 'password-reset',
  components: { PasswordRules },
  props: {
    uid: String,
    token: String
  },
  data: () => ({
    formIsValid: false,
    fieldRules: [v => !!v || 'Ввод в поле обязателен'],
    user: {
      uid: '',
      token: '',
      newPassword: '',
      newPasswordRepeated: ''
    },
    color: 'deep-orange lighten-1'
  }),
  mounted() {
    this.user.uid = this.uid;
    this.user.token = this.token;
    // Заменим параметры в адресной строке для безопасности.
    // Они уже сохранены в data
    this.$router.replace({
      name: 'password-reset',
      params: { uid: 'new', token: 'password' }
    });
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'main' });
    },
    save() {
      if (!this.formIsValid) {
        this.$notify.error('Форма заполнена некорректно');
        return;
      }
      this.$store
        .dispatch('changePasswordWithToken', this.user)
        .then(response => {
          this.$notify.success(response.data.message);
          this.$router.push({ name: 'main' });
        })
        .catch(error => {
          this.$notify.error(error.response.data.message);
        });
    }
  }
};
</script>
