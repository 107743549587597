import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import Main from '../views/Main.vue';
import Login from '../views/Login.vue';
import PasswordReset from '../views/PasswordReset.vue';
import Dashboard from '../views/Dashboard.vue';
import e404 from '../views/e404.vue';

Vue.use(VueRouter);

// Маршруты, которые отлавливает роутер
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { layout: 'empty-layout' }
  },
  {
    path: '/logout',
    name: 'logout',
    redirect: '/login'
  },
  {
    path: '/auth/reset/:uid/:token',
    name: 'password-reset',
    props: true,
    component: PasswordReset,
    meta: { layout: 'empty-layout' }
  },
  {
    path: '/app',
    name: 'main',
    component: Main,
    meta: { layout: 'main-layout' }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { layout: 'main-layout' }
  },
  {
    path: '/404',
    name: '404',
    component: e404,
    meta: {
      layout: 'empty-layout'
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  // Сначала обработаем маршруты,
  // доступные для неавторизованных пользователей
  if (to.name === 'password-reset') {
    next();
    return;
  }
  if (to.name !== 'login' && store.state.isAuthenticated === 'false')
    next({ name: 'login' });
  else if (to.name === 'login' && store.state.isAuthenticated === 'true')
    next({ name: 'main' });
  else next();
});

export default router;
