<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>

<script>
// Пустой лэйаут
export default {
  name: 'empty-layout'
};
</script>
